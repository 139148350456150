<template>
  <v-container fluid class="mb-5 mt-1">
    <h3 class="mb-2 mt-2">Modul IGD</h3>
    <v-row justify="center" class="mt-1 ma-2">
      <v-col cols="12" lg="6" sm="6" md="6">
        <v-autocomplete
          v-model="search"
          :items="searchResults"
          :loading="isLoading"
          :search-input.sync="query"
          item-text="patient_name"
          item-value="folio_id"
          chips
          outlined
          dense
          color="blue-grey lighten-2"
          label="Cari Data Pasien IGD"
          hide-no-data
          @input="loadItems"
        >
          <template v-slot:item="{ item }">
            <v-chip class="ma-2" label dark color="teal" @click="setData(item)">
              <v-icon left> mdi-account-circle-outline </v-icon>
              No.RM&nbsp;:&nbsp;<b>{{ item.medrec_no }}</b
              >&nbsp;|&nbsp;Nama Pasien&nbsp;:&nbsp;<b>{{
                item.salutation + item.patient_name
              }}</b
              >&nbsp;|&nbsp;Tanggal Lahir:&nbsp; <b>{{ item.birth_date }}</b
              >| &nbsp;<u>Tanggal Masuk</u>&nbsp;:&nbsp;<b>{{
                item.service_date
              }}</b>
            </v-chip>
            <!--        <v-list-item-content>-->
            <!--          <v-list-item-title>{{item.patient_name}}</v-list-item-title>-->
            <!--        </v-list-item-content>-->
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="6" sm="6">
        <v-text-field
          v-model="tglPelayanan"
          label="Tanggal Pelayanan"
          outlined
          @change="loadItems"
          dense
          hide-details="auto"
          type="date"
        />
      </v-col>
    </v-row>
    <v-tabs
      slider-color="yellow"
      centered
      stacked
      show-arrows
      scrollable
      danse
      class="mb-1"
    >
      <!-- <v-tab>
        <v-icon left> mdi-file</v-icon>
        CPPT
      </v-tab> -->
      <v-tab @click="setTriage()">
        <v-icon left> mdi-file</v-icon>
        Triage
      </v-tab>
      <v-tab @click="tabKanan = 1">
        <v-icon left> mdi-file</v-icon>
        CPPT
      </v-tab>
      <v-tab @click="tabKanan = 7">
        <v-icon left> mdi-file</v-icon>
        Resume Medis Rawat Inap
      </v-tab>
      <!-- <v-tab>
        <v-icon left> mdi-file</v-icon>
        OK
      </v-tab> -->
      <v-tab @click="tabKanan = 2">
        <v-icon left> mdi-file</v-icon>
        Penunjang
      </v-tab>
      <!-- <v-tab>Asesmen Rawat Jalan</v-tab> -->
      <v-tab @click="tabKanan = 3">
        <v-icon left> mdi-account</v-icon>
        Konsultasi
      </v-tab>
      <v-tab @click="tabKanan = 4">
        <v-icon left> mdi-mortar-pestle-plus</v-icon>
        E-Obat
      </v-tab>
      <v-tab @click="tabKanan = 5">
        <v-icon left> mdi-order-bool-descending-variant</v-icon>
        E-LABOR/RADIOLOGI
      </v-tab>
      <v-tab @click="setDataFormTransfer()">
        <v-icon left> mdi-file-move</v-icon>
        Formulir Transafer
      </v-tab>
    </v-tabs>

    <!-- <v-tabs-items v-model="tabKanan"> -->
    <!-- <v-tab-item>
        <CPPT/>
      </v-tab-item> -->
    <div :hidden="tabKanan !== 0">
      <div v-if="tabKanan === 0">
        <Triage />
      </div>
    </div>

    <div :hidden="tabKanan !== 1">
      <div v-if="tabKanan === 1">
        <CpptIGD />
      </div>
    </div>
    <!-- <v-tab-item>
        <FormOk />
      </v-tab-item> -->
    <div :hidden="tabKanan !== 2">
      <div v-if="tabKanan === 2">
        <ListLabor />
      </div>
    </div>
    <div :hidden="tabKanan !== 3">
      <div v-if="tabKanan === 3">
        <LembarKonsultasi />
      </div>
    </div>
    <div :hidden="tabKanan !== 4">
      <div v-if="tabKanan === 4">
        <ResepIgd />
      </div>
    </div>
    <div :hidden="tabKanan !== 5">
      <div v-if="tabKanan === 5">
        <Penunjang />
      </div>
    </div>
    <div :hidden="tabKanan !== 6">
      <div v-if="tabKanan === 6">
        <FormTransferIGD />
      </div>
    </div>
    <div :hidden="tabKanan !== 7">
      <div v-if="tabKanan === 7">
        <ResumeMedisRawatInap />
      </div>
    </div>
    <!-- </v-tabs-items> -->
  </v-container>
</template>
<script>
import Triage from './AsesmenTriage'
import Penunjang from './Penunjang'
// eslint-disable-next-line no-unused-vars
import LembarKonsultasi from './LembarKonsultasi'
import ResumeMedisRawatInap from './ResumeMedisRawatInap'
import ResepIgd from './ResepIgd.vue'
// eslint-disable-next-line no-unused-vars
import CpptIGD from './CpptIgd.vue'
// import FormOk from '../ok/FormOK.vue'
import ListLabor from '../../components/ListLabor.vue'

// import CPPT from '../rawat-inap/CpptRawatInap.vue'
import FormTransferIGD from './FormTransferIGD.vue'
import { baseUrl, errorMsg } from '@/plugins/supports'
// import axios from 'axios'

export default {
  components: {
    Triage,
    Penunjang,
    ListLabor,
    CpptIGD,
    // FormOk,
    // CPPT,
    // eslint-disable-next-line vue/no-unused-components
    LembarKonsultasi,
    ResumeMedisRawatInap,
    FormTransferIGD,
    ResepIgd
  },
  data () {
    return {
      tabKanan: 0,
      search: null,
      query: '',
      searchResults: [],
      isLoading: false,
      limit: 10,
      offset: 0,
      totalCount: 0
    }
  },
  watch: {
    query () {
      this.loadItems()
    }
  },
  created () {
    this.jamSekarang()
    if (!this.$route.query.no_rm) {
      errorMsg('PILIH DAHULU PASIENNYA')
      this.$router.push({
        name: 'FORMIGD'
      })
      return
    }

    this.setTriage()
  },
  methods: {
    setTriage () {
      this.tabKanan = 0
      this.$root.$emit('SETDATAULANG') // like this
    },
    jamSekarang () {
      const today = new Date()
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = '0' + dd
      if (mm < 10) mm = '0' + mm

      const formattedToday = dd + '/' + mm + '/' + yyyy
      const todays = yyyy + '-' + mm + '-' + dd
      this.tglSekarang = formattedToday
      this.tglPelayanan = todays
    },
    setData (v) {
      // console.log(item)
      this.$router.push({
        name: 'ModulIgd',
        // params: { no_rm: v.medrec_no, folio_id: v.folio_id, registration_id: v.registration_id }
        query: {
          no_rm: v.medrec_no,
          folio_id: v.folio_id,
          registration_id: v.registration_id,
          sub_unit_id: v.sub_unit_id,
          type: 'igd'
        }
      })
      this.$root.$emit('SETDATAULANG') // like this
    },

    async loadItems () {
      this.isLoading = true
      try {
        // eslint-disable-next-line no-undef
        await $.get(
          baseUrl + 'igd/cari-pasien-igd',
          {
            search: this.query,
            limit: this.limit,
            offset: this.offset,
            tgl: this.tglPelayanan
          },
          (res) => {
            this.searchResults = res.data
            this.totalCount = res.totalCount
            this.isLoading = false
          },
          'JSON'
        )
        // const response = await axios.get('/product/search', {
        //   params: {
        //     query: this.query,
        //     limit: this.limit,
        //     offset: this.offset
        //   }
        // })
        // this.searchResults = response.data.items
      } catch (error) {
        console.error('Error fetching search results:', error)
      }
      this.isLoading = false
    },
    setDataFormTransfer () {
      // console.log('sdasdad')
      this.tabKanan = 6
      this.$root.$emit('setdatatransfer') // like this
    }
  }
}
</script>
<style>
.float-group {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
