<template>
  <v-container fluid class="mb-5 mt-1">
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="4">
        <div class="mt-2" v-if="data.demografi.notes">
          <v-alert shaped prominent type="error">
            Catatan IGD : <u>{{ data.demografi.notes }}</u>
          </v-alert>
        </div>
        <v-btn
          class="ma-1"
          @click="formNonRacikan()"
          color="indigo"
          dark
        >
          Non Racikan <v-icon small>mdi-plus</v-icon></v-btn>
        <v-btn
          @click="formRacikan()"
          class="ma-1"
          color="blue"
          dark
        >
          Racikan <v-icon small>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn  @click="bhp()"
        dark
        class="ma-1" color="orange darken-2">
          BHP <v-icon small>mdi-plus</v-icon>
        </v-btn> -->

        <!-- <v-btn
          dark
          class="ma-1"
          @click="riwayatalergi()"
          color="blue darken-2"
        >
          Riwayat Alergi
        </v-btn> -->
        <v-card color="teal darken-1 mt-2" dark>
          <v-card-title>Demografi Pasien</v-card-title>

          <v-card-text>
            <v-row class="mt-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
      <v-col cols="12" lg="8" md="8" sm="8">
        <!--        {{listOrderanResep}}-->
        <h5>HISTORY ORDERAN</h5>
        <v-progress-linear
          v-show="progress"
          color="primary"
          indeterminate
          rounded
          height="6"
        />
        <table class="table table-bordered table-sm" width="100">
          <thead>
          <tr class="table-primary">
            <th class="text-center" style="vertical-align: middle" width="25%">NO. ORDERAN</th>
            <th class="text-center" style="vertical-align: middle">ITEM OBAT</th>
          </tr>
          </thead>
          <tbody>
          <tr
          class="table-warning"
          v-for="(itemorderan,indexorder) of listOrderanResep" :key="indexorder">
            <td style="vertical-align: middle">
              <b>{{ itemorderan.no_order_resep }}</b><br>
              Jam Order : <u>{{ itemorderan.tgl_order }}</u>
            </td>
            <td>
              <div class="ma-2" v-if="!isEmpty(itemorderan.item_order)">
                <v-card
                  class="mx-auto "
                >
                  <v-toolbar
                    flat
                    color="teal"
                    dark
                  >
                    <v-btn icon @click="editnonracikan(itemorderan.id_order_resep)">
                      <v-icon medium>mdi-pencil</v-icon>
                    </v-btn>
                    <v-toolbar-title>Obat Non Racikan</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-chip
                      color="teal"
                      label
                      dark
                      class="ma-1"
                      text-color="white"
                      v-for="(itemobatnonracikan ,indexitemobatnonracikan) of itemorderan.item_order"
                      :key="indexitemobatnonracikan"
                    >
                      <v-icon left>
                        mdi-pill
                      </v-icon>
                      Nama Obat :&nbsp;<b><u>{{ itemobatnonracikan.value.text }}</u> | </b>&nbsp;Signa
                      :&nbsp;<b><u>{{ itemobatnonracikan.signa }}</u></b> &nbsp;| Waktu
                      Pemberian:&nbsp;<b><u>{{ itemobatnonracikan.waktu }}</u></b>
                    </v-chip>
                  </v-card-text>
                </v-card>
              </div>
              <div v-if="!isEmpty(itemorderan.item_order_racikan)" class="ma-2">
                <v-card
                  class="mx-auto"
                >
                  <v-toolbar
                    flat
                    color="teal"
                    dark
                  >
                    <v-btn @click="editracikan(itemorderan.id_order_resep)" icon>
                      <v-icon medium>mdi-pencil</v-icon>
                    </v-btn>
                    <v-toolbar-title>Obat Racikan</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <!--                    {{itemorderan.item_order_racikan}}-->
                    <div v-for="(itemracikan,indexracikan) of itemorderan.item_order_racikan.listRacikan"
                         :key="indexracikan">
                      <p><b><u>{{ itemracikan.id_racikan }}</u></b></p>
                      <v-divider></v-divider>
                      <div v-for="(itemobatracikan,indexobatracikan) of itemorderan.item_order_racikan.listObat"
                           :key="indexobatracikan">
                        <v-chip
                          color="teal"
                          label
                          dark
                          class="ma-1"
                          text-color="white"
                          v-if="itemracikan.id_racikan === itemobatracikan.id_racikan"
                        >
                          <v-icon left>
                            mdi-pill
                          </v-icon>
                          <u><b>{{ itemobatracikan.value?.text }}</b></u> &nbsp;|&nbsp;Dosis&nbsp;:&nbsp;<u><b>{{ itemobatracikan?.dosis }}</b></u>&nbsp;|&nbsp;Satuan
                          Obat :&nbsp; <u><b>{{ itemobatracikan?.uom }}</b></u>
                        </v-chip>

                      </div>
                      <v-divider></v-divider>

                      <p class="mt-1">Jumlah Obat &nbsp;:&nbsp;<u><b>{{ itemracikan.jumlahObat }}</b></u><br>
                        Signa &nbsp;:&nbsp;<u><b>{{ itemracikan.signa }}</b></u><br>
                        Waktu Pemberian &nbsp;:&nbsp;<u><b>{{ itemracikan.dosis }}</b></u></p>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <NonRacikanIgd
      :show-dialog="showDialogNonRacikan"
      :negative-button="dialogActionNBtnLKirimNonRacikan"
      :disabled-negative-btn="dialogDisableNBtnKirimNonRacikan"
    />
    <RacikanIgd
      :show-dialog="showDialogRacikan"
      :negative-button="dialogActionNBtnLKirimRacikan"
      :disabled-negative-btn="dialogDisableNBtnKirimRacikan"
    />
    <EditNonRacikan
      ref="editNonRacikan"
      :show-dialog="showEditDialogNonRacikan"
      :negative-button="dialogEditActionNBtnLKirimNonRacikan"
      :disabled-negative-btn="dialogEditDisableNBtnKirimNonRacikan"
    />
    <EditRacikan
      ref="editRacikan"
      :show-dialog="showEditDialogRacikan"
      :negative-button="dialogEditActionNBtnLKirimRacikan"
      :disabled-negative-btn="dialogEditDisableNBtnKirimRacikan"
    />
  </v-container>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/demografi-all'
import Dialog from '@/components/Dialog'
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg, isEmpty
} from '@/plugins/supports'
import NonRacikanIgd from './form-obat/NonRacikanIgd.vue'
import RacikanIgd from './form-obat/Racikan.vue'
import EditNonRacikan from './form-obat/EditNonRacikan.vue'
import EditRacikan from './form-obat/EditRacikan.vue'

export default {
  computed: {
    // nonRacikan () {
    //   return nonRacikan
    // }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget,
    NonRacikanIgd,
    // eslint-disable-next-line vue/no-unused-components
    RacikanIgd,
    // eslint-disable-next-line vue/no-unused-components
    EditNonRacikan,
    // eslint-disable-next-line vue/no-unused-components
    EditRacikan,
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog
  },
  data () {
    return {
      url_emr: 'http://emr.rs-syafira.com/',
      searchKonsultasi: '',
      permintaans_page: 1,
      progress: false,
      regid: this.$route.query.registration_id,
      fields: [
        { value: '', catatan: '', jumlah: '', signa: '', waktu: '' }
      ],
      tags: [
        'Work',
        'Home Improvement',
        'Vacation',
        'Food',
        'Drawers',
        'Shopping',
        'Art',
        'Tech',
        'Creative Writing'
      ],
      // autocompleteData: ['Option 1', 'Option 2', 'Option 3'],
      autocompleteData: [],
      permintaans_per_page: 9,
      permintaan_pagination_container: '',
      permintaans_total: '',
      isActive: false,
      items: ['Rawat Bersama', 'Ahli Rawat', 'Konsul'],
      master: Master,
      user: JSON.parse(localStorage.getItem('user')),
      listOrderanResep: [],
      listOrderanResepRacikan: [],
      loading: false,

      // DIALOG NON RACIKAN
      showDialogNonRacikan: false,
      dialogDisableNBtnKirimNonRacikan: false,
      dialogActionNBtnLKirimNonRacikan: () => {
        this.showDialogNonRacikan = false
        this.getDataOrderResep(this.$route.query.no_rm, this.$route.query.registration_id)
      },
      // EDIT DIALOG NON RACIKAN
      showEditDialogNonRacikan: false,
      dialogEditDisableNBtnKirimNonRacikan: false,
      dialogEditActionNBtnLKirimNonRacikan: () => {
        this.showEditDialogNonRacikan = false
        this.getDataOrderResep(this.$route.query.no_rm, this.$route.query.registration_id)
      },
      // EDIT DIALOG RACIKAN
      showEditDialogRacikan: false,
      dialogEditDisableNBtnKirimRacikan: false,
      dialogEditActionNBtnLKirimRacikan: () => {
        this.showEditDialogRacikan = false
        this.getDataOrderResep(this.$route.query.no_rm, this.$route.query.registration_id)
      },

      // DIALOG RACIKAN
      showDialogRacikan: false,
      dialogDisableNBtnKirimRacikan: false,
      dialogActionNBtnLKirimRacikan: () => {
        this.showDialogRacikan = false
        this.getDataOrderResep(this.$route.query.no_rm, this.$route.query.registration_id)
      },
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      },
      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => this.postSave()
    }
  },
  mounted () {
    // this.getDataListKonsultasi()
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.pegawai = JSON.parse(localStorage.getItem('user'))
      this.getDataPasien(this.$route.query.no_rm)

      setTimeout(() => {
        this.getDataOrderResep(this.$route.query.no_rm, this.$route.query.registration_id)
      }, 1000)
    }
  },
  methods: {
    bhp () {

    },
    editnonracikan (id) {
      this.showEditDialogNonRacikan = true
      this.$refs.editNonRacikan.getDataResep(id)
    },
    editracikan (id) {
      this.$refs.editRacikan.getDataResep(id)
      this.showEditDialogRacikan = true
      // this.$refs.editNonRacikan.getDataResep(id)
    },
    isEmpty,
    riwayatalergi () {

    },
    formNonRacikan () {
      this.showDialogNonRacikan = true
    },
    formRacikan () {
      this.showDialogRacikan = true
    },
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          const date = new Date(response.data.results.tanggal_lahir)

          const day = date.toLocaleString('default', { day: '2-digit' })
          const month = date.toLocaleString('default', { month: 'short' })
          const year = date.toLocaleString('default', { year: 'numeric' })
          // console.log(response.data.results.tanggal_lahir)
          const tglLahir = day + '/' + month + '/' + year
          this.data.demografi.umur = this.umur(tglLahir)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getDataOrderResep (rm, regId) {
      this.progress = true
      const url = {
        rm: rm,
        regId: regId
      }

      var listorder = []

      // eslint-disable-next-line no-undef
      $.get(baseUrl + 'igd/get-data-resep', url, (res) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = res
        if (con) {
          this.progress = false
          results.forEach((obj) => {
            // console.log(obj.no_order_resep)
            // eslint-disable-next-line no-undef
            const itemorder = LZString.decompressFromBase64(obj.item_order)
            // eslint-disable-next-line no-undef
            const itemorderRacikan = LZString.decompressFromBase64(obj.item_order_racikan)
            const itemorderpase = JSON.parse(itemorder)
            const itemorderpaseracikan = JSON.parse(itemorderRacikan)
            listorder.push({
              id_order_resep: obj.id_order_resep,
              no_order_resep: obj.no_order_resep,
              no_reg: obj.no_reg,
              no_folio: obj.no_folio,
              no_rm: obj.no_rm,
              tgl_order: obj.tgl_order,
              item_order: itemorderpase,
              item_order_racikan: itemorderpaseracikan
            })
          })
          this.listOrderanResep = listorder
        } else {
          this.progress = false
          this.listOrderanResep = []
        }
      }, 'JSON ')
    },
    umur (tglLahir) {
      var dob = new Date(tglLahir)
      var dobYear = dob.getYear()
      var dobMonth = dob.getMonth()
      var dobDate = dob.getDate()
      var yearAge = ''
      // get the current date from the system
      var now = new Date()
      // extract the year, month, and date from current date
      var currentYear = now.getYear()
      var currentMonth = now.getMonth()
      var currentDate = now.getDate()

      // declare a variable to collect the age in year, month, and days
      var age = {}
      var ageString = ''

      // get years
      yearAge = currentYear - dobYear

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        var monthAge = currentMonth - dobMonth
      } else {
        yearAge--
        // eslint-disable-next-line no-redeclare
        var monthAge = 12 + currentMonth - dobMonth
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate
      } else {
        monthAge--
        // eslint-disable-next-line no-redeclare
        var dateAge = 31 + currentDate - dobDate

        if (monthAge < 0) {
          monthAge = 11
          yearAge--
        }
      }
      // group the age in a single variable
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      } else if (age.years === 0 && age.months === 0 && age.days > 0) {
        ageString = ' ' + age.days + ' Hari'
        // eslint-disable-next-line brace-style
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months === 0 && age.days === 0) {
        ageString = age.years + ' Tahun. Happy Birthday!!'
      } else if (age.years > 0 && age.months > 0 && age.days === 0) {
        ageString = age.years + ' Tahun dan ' + age.months + ' Bulan.'
      } else if (age.years === 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + ' Bulan dan ' + age.days + ' Hari.'
      } else if (age.years > 0 && age.months === 0 && age.days > 0) {
        ageString = age.years + ' Tahun, dan' + age.days + ' Hari.'
      } else if (age.years === 0 && age.months > 0 && age.days === 0) {
        ageString = age.months + ' Bulan.'
        // eslint-disable-next-line brace-style
      }
      // when current date is same as dob(date of birth)
      else {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      }

      // console.log(ageString)
      return ageString
    }
  }
}
</script>

<style scoped>

</style>
