<template>
  <div>
    <v-card elevation-3>
      <v-card-title>List Asesmen Triage</v-card-title>

      <v-card-text>
        <div class="row justify-content-center">
          <div class="col-md-12">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" @click="initPermintaanPreviosPage"
                    >Sebelumnya</a
                  >
                </li>
                <li class="page-item">
                  <a
                    class="page-link"
                    v-html="permintaan_pagination_container"
                  ></a>
                </li>
                <li class="page-item">
                  <a class="page-link" @click="initPermintaanNextPage"
                    >Selanjutnya</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div
          v-for="(itemAsesmen, index) of history"
          :key="index"
          class="list-group mb-2"
        >
          <div
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div class="d-flex w-100 justify-content-between">
              <p class="mb-1">
                <v-icon left small>mdi-doctor</v-icon> Nama Dokter :
                <b
                  ><u>{{ itemAsesmen.createdByLog?.NAMA }}</u></b
                >
              </p>
            </div>
            <div class="d-flex w-100 justify-content-between">
              <p class="mb-1">
                <v-icon left small>mdi-calendar-badge-outline</v-icon> Tanggal
                Asesmen :
                <b
                  ><u>{{ itemAsesmen.tglDibuat }}</u></b
                >
              </p>
            </div>

            <v-divider inset></v-divider>
            <!-- <b class="mb-2"
              ><v-icon small left>mdi-scale</v-icon>Skala Triage :</b> -->
            <p class="mb-1">
              <b class="red--text">S : </b>
              {{ itemAsesmen.pemeriksaan?.subjektif }}
            </p>
            <v-divider></v-divider>
            <p class="mb-1">
              <b class="red--text">O : </b>
              {{ itemAsesmen.pemeriksaan?.objektif }}
            </p>
            <v-divider></v-divider>
            <p class="mb-1">
              <b class="red--text">A : </b>
              {{ itemAsesmen.pemeriksaan?.asessment }} <br />
              {{ itemAsesmen.pemeriksaan?.diagnosa_utama }}
            </p>
            <v-divider></v-divider>
            <p class="mb-1">
              <b class="red--text">P : </b>
              {{ itemAsesmen.pemeriksaan?.planning }}
              {{ itemAsesmen.pemeriksaan?.planning_racikan }}
            </p>
            <v-divider></v-divider>
            <v-btn
              color="green"
              @click="cetakAsesmen(itemAsesmen.folio_id)"
              class="mt-1 btn-block"
              dark
              small
              >Cetak Asesmen</v-btn
            >
            <v-btn
              color="info"
              @click="cetakResume(itemAsesmen.folio_id)"
              class="mt-1 btn-block"
              dark
              small
              >Cetak Resume</v-btn
            >
            <v-btn
              color="info"
              @click="logHistory(itemAsesmen.log_edit)"
              class="mt-1 btn-block"
              dark
              small
              >Log History Edit</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showDialogResume" max-width="1280">
      <v-card>
        <v-card-title class="headline"> IGD </v-card-title>
        <v-card-text>
          <div id="hasil"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogLog"  max-width="580">
      <v-card>
        <v-card-title class="headline"> Log Edit </v-card-title>
        <v-card-text>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="(itemlog,indexlog) of edit_log" :key="indexlog">
              <h6 class="mb-1">{{ itemlog.NAMA }}</h6>
              <p class="mb-1">Tanggal : {{ itemlog?.TGL }}</p>
              <p class="mb-1">Role : {{ itemlog?.role }}</p>

            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'

export default {
  data () {
    return {
      loading: false,
      selection: 1,
      history: [],
      reveal: false,
      showDialogResume: false,
      showDialogLog: false,
      permintaans_page: 1,
      edit_log: '',
      permintaans_per_page: 9,
      permintaan_pagination_container: '0-0 of 0',
      permintaans_total: '',

      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  mounted () {
    this.$root.$on('SETDATAULANG', (item) => {
      if (this.$route.query.no_rm != null) {
        setTimeout(() => {
          this.getHistoryAsesmen()
        }, 100)
      }
    })
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getHistoryAsesmen()
    }
  },
  methods: {
    logHistory (RESULTS) {
      this.showDialogLog = true
      this.edit_log = RESULTS
    },
    reserve () {
      this.loading = true

      setTimeout(() => (this.loading = false), 1000)
    },
    getHistoryAsesmen () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'igd/history-asesmen',
        {
          pg: this.permintaans_page,
          ppg: this.permintaans_per_page,
          rm: this.$route.query.no_rm
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            const pagination = results.pagination
            this.permintaan_pagination_container = pagination
            this.permintaans_total = results.total_pages
            // this.history = results.list
            const history = []

            for (const item of results.list) {
              // eslint-disable-next-line no-undef
              var data = LZString.decompressFromBase64(item.pemeriksaan)
              var dataDec = JSON.parse(data)
              // eslint-disable-next-line no-undef
              var dataLog = LZString.decompressFromBase64(item.logEdit)
              // // eslint-disable-next-line no-unused-vars
              var dataLogDec = JSON.parse(dataLog)

              // eslint-disable-next-line no-undef
              var createdByLog = LZString.decompressFromBase64(item.datadokter)

              // console.log(item)
              // // eslint-disable-next-line no-unused-vars
              var dataCreatedDec = JSON.parse(createdByLog)

              // console.log(dataDec + 'asdsadas')
              dataLogDec.sort((a, b) => new Date(b.TGL) - new Date(a.TGL))

              history.push({
                id_triage: item.id_triage,
                namaDokter: item.namaDokter,
                tglDibuat: item.tglDibuat,
                folio_id: item.folio_id,
                no_rm: item.no_rm,
                registration_id: item.registration_id,
                unit: item.unit,
                // eslint-disable-next-line no-undef
                pemeriksaan: dataDec,
                log_edit: dataLogDec,
                createdByLog: dataCreatedDec
              })
            }
            this.history = history
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getHistoryAsesmen()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getHistoryAsesmen()
    },
    cetakAsesmen (folioId) {
      this.showDialogResume = true
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'rme/view-asesmen-igd',
        { folio_id: folioId },
        (res) => {
          setTimeout(() => {
            // eslint-disable-next-line no-undef
            $('#hasil').html(res)
          }, 500)
        }
      )
    },
    cetakResume (folioId) {
      this.showDialogResume = true
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        $.get(
          baseUrlV2 + 'rme/view-resume-igd',
          { folio_id: folioId },
          (res) => {
            // eslint-disable-next-line no-undef
            // eslint-disable-next-line no-undef
            $('#hasil').html(res)
          }
        )
      }, 500)
    }
  }
}
</script>
