<template>
  <div>
<!--    {{vModel}}-->
    <v-autocomplete
      v-model="vModel"
      return-object
      item-text="text"
      item-value="id"
      hide-no-data
      hide-selected
      dense
      outlined
      :cache-items="true"
      :disabled="isReadonly"
      :rules="rules"
      :items="items"
      :search-input.sync="searchDokter"
      label="Cari"
    >
    </v-autocomplete>
    <v-chip color="primary" v-if="!!vModel">{{ vModel.text }}</v-chip>
  </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '@/plugins/supports'

export default {
  name: 'AutoCompleteSingle',
  props: {
    property: {
      type: [Array, Object],
      required: false,
      default: () => {
      }
    },
    cvalue: {
      type: [Array, Object, String, Function, Boolean],
      required: false,
      default: () => null
    },
    syncValue: {
      type: Function,
      default: (v) => v,
      required: true
    },
    rules: { type: [Array], required: false, default: () => [] },
    label: { type: [String, Boolean], required: false, default: () => '' },
    vclass: { type: String, required: false, default: () => '' }
  },
  created () {
    const { attrs, events } = this.property || {}
    this.attrs = attrs || {}
    this.events = events || {}
  },
  data () {
    return {
      searchDokter: '',
      modelicd: '',
      isReadonly: false,
      isLoading: false,
      vModel: null,
      dataVmodel: null,
      attrs: {},
      events: {},
      select: { state: 'Florida', abbr: 'FL' },
      items: []
    }
  },
  methods: {
    deleteItem (index) {
      // console.log('hantu' + index)
      this.vModel.splice(index, 1)
      // this.vModel.splice(index, 1)
    }

  },
  mounted () {
    this.vModel = this.cvalue
    this.syncValue(this.vModel)
    // this.$emit('input', this.vModel)
    // console.log(this.vModel + 'ADA NGGAK ISINYA')
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      console.log(n + 'VOMODEL')
      this.vModel = n
    },
    emitInput () {
    },
    searchDokter (val) {
      const data = {
        reg_id: this.$route.query.registration_id,
        searchTerm: val
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'igd/get-data-obat', data,
        (response) => {
          // if (con) {
          this.items = response
        },
        'JSON'
      )
    }
  }
}
</script>
