<template>
  <v-dialog v-model="showDialog" :persistent="true" max-width="1880">
    <v-card>
      <v-card-title>Form Laboratorium</v-card-title>
      <v-card-subtitle>
        <button
          v-if="negativeButton"
          :disabled="disabledNegativeBtn"
          @click="negativeButton"
          type="button"
          class="btn btn-danger btn-md text-white mt-3"
        >
          Tutup
        </button>
      </v-card-subtitle>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" lg="4" sm="4" md="4">
            <v-btn
              class="m-1"
              dark
              @click="paket('12677')"
              color="blue darken-1"
              small
              >Paket Pre Op 1 Per Item</v-btn
            >
            <v-btn
              dark
              class="m-1"
              @click="paket('12679')"
              color="blue darken-1"
              small
              >Paket Pre Op 2 Per Item</v-btn
            >
            <p>
              Button Ini Khusus Digunakan Jika Pemeriksaan Operasi Dilakukan Per
              Item, Jika Pemeriksaan Diminta Paket Pilih Button
              <span class="text-danger"><b>Merah Paket Pre Op!!</b></span>
            </p>
            <v-simple-table class="table table-bordered">
              <thead>
                <tr>
                  <th style="vertical-align: middle; text-align: center">
                    <h6>Aksi</h6>
                  </th>
                  <th style="vertical-align: middle" width="90%">
                    <h6>Nama Tindakan</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(itemOrder, index) in listOrder" :key="index">
                  <td style="vertical-align: middle" class="text-center">
                    <v-btn
                      fab
                      class="error"
                      @click="deleteTindakan(index)"
                      x-small
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                  <td style="vertical-align: middle">
                    <b
                      ><u>{{ itemOrder.item_name }}</u></b
                    >
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td style="vertical-align: middle" colspan="2">
                    <v-autocomplete
                      return-object
                      outlined
                      class="mt-2"
                      dense
                      item-text="text"
                      item-value="value"
                      v-model="tindakan"
                      :items="getTindakan"
                      :search-input.sync="searchTindakan"
                      @change="addTindakan()"
                      label="Nama Item"
                      placeholder="Cari Nama Item Laboratorium"
                    ></v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle" colspan="2">
                    <v-textarea
                      class="mt-4 ml-2 mr-2"
                      auto-grow
                      outlined
                      label="Diagnosa/Keterangan Klinis Tambahan"
                      rows="4"
                      row-height="20"
                      v-model="keterangan"
                    ></v-textarea>
                  </td>
                </tr>
                <tr>
                  <td style="vertical-align: middle" colspan="2">
                    <v-select
                      class="mt-4 ml-2 mr-2"
                      :items="itemsPilih"
                      label="Jenis Permintaan"
                      outlined
                      v-model="jenisPermintaan"
                    ></v-select>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <v-btn
                      class="btn-block ma-2"
                      color="primary darken-1"
                      @click="simpan"
                      :disabled="listOrder.length === 0"
                      dark
                      large
                    >
                      Order Laboratorium
                    </v-btn>
                  </td>
                </tr>
              </tfoot>
            </v-simple-table>
          </v-col>
          <v-col cols="12" lg="8" sm="8" md="8">
            <v-row class="ma-1">
              <v-col cols="12" lg="12" sm="12" md="12">
                <v-text-field
                  label="Cari Group Item"
                  v-model="searchQuery"
                  @keyup="cariitem"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                v-for="(itemlabor, indexlabor) of blanko"
                :key="indexlabor"
                cols="12"
                lg="4"
                sm="4"
                md="4"
              >
                <v-btn
                  :color="checkwarna(itemlabor.item_group_id)"
                  class="btn-block"
                  large
                  style="font-size: 11px"
                  dark
                  @click="
                    setItem(
                      itemlabor.list,
                      itemlabor.item_group_id,
                      itemlabor.group_name
                    )
                  "
                >
                  <b>{{ itemlabor.group_name }}</b>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="tol" max-width="500">
      <v-card>
        <v-card-title class="headline primary--text">
          {{ namaGroup }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            label="Cari Nama Item Labor"
            v-model="searchQuery"
            outlined
            dense
          ></v-text-field>
          <ul class="list-group">
            <li
              v-for="(child, indexchild) of searchListItemDetail"
              :key="indexchild"
              class="list-group-item"
            >
              <v-btn
                style="font-size: 12px"
                class="btn-block"
                color="primary"
                medium
                dark
                @click="settindakan(child.item_id, child.item_name)"
              >
                <span v-if="child.item_group_id === group_id"
                  >{{ child.item_name }}
                </span>
              </v-btn>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import AutoComplateAjax from '../AutoCompleteAjax.vue'
// eslint-disable-next-line no-unused-vars
import { apiKedua, baseUrl, errorMsg, successMsg } from '@/plugins/supports'

export default {
  name: 'Dialog',
  // eslint-disable-next-line vue/no-unused-components
  components: { AutoComplateAjax },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },

    itemUse: {
      require: false,
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      content: '',
      filteredSuggestions: [],
      showSuggestions: false,
      selectedRacikan: '0',
      listObat: [],
      banyakRacikan: [],
      tindakan: '',
      // getTindakan: [],
      listOrder: [],
      searchTindakan: '',
      jenisPermintaan: '',
      keterangan: '',
      blanko: [],
      searchQuery: '',
      checkbox: '',
      searchQueryItem: '',
      tol: false,
      itemTindakan: [],

      listChild: [],
      itemsPilih: ['', 'CITO', 'PERMINTAAN'],
      group_id: '',
      namaGroup: '',
      keteranganAcc: ''
    }
  },
  computed: {
    getTindakan () {
      return this.itemTindakan.map((v) => ({
        text: v.item_name,
        value: v.item_id
      }))
    },
    searchListItemDetail () {
      // console.log('123213123')
      //   console.log(this.searchQuery);
      if (this.searchQuery) {
        // console.log(this.blanko)
        return this.listChild.filter((item) => {
          return item.item_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
          // return item.item_name.startsWith(this.searchQueryItem.toLowerCase())
        })
      } else {
        return this.listChild
      }
    }
  },
  methods: {
    deleteTindakan (v) {
      this.listOrder.splice(v, 1)
    },
    setItem (v, g, n) {
      this.tol = true
      this.listChild = v
      this.group_id = g
      this.namaGroup = n
    },

    settindakan (id, name) {
      this.id_order = this.id_order + 1

      var self = this
      this.listOrder.forEach((val, index) => {
        if (val.item_id === id) {
          self.listOrder.splice(index, 1)
          return false
        }
      })
      this.listOrder.push({
        no: this.id_order,
        item_id: id,
        item_name: name
      })

      this.tindakan = []

      // console.log(this.order)
    },
    getData () {
      var blanko = []
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'labor/blanko-labor',
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            for (const item of results) {
              blanko.push({
                item_type_id: item.item_type_id,
                item_group_id: item.item_group_id,
                // list: this.getDataSingle(item.item_group_id),
                group_name: item.group_name,
                list: item.list
              })
            }
          } else {
          }
        },
        'JSON'
      )
      this.blanko = blanko
    },
    checkwarna (id) {
      var seringdipakai = ['129', '182', '136', '131', '211', '199']
      if (id === '210') {
        return 'red'
      } else if (seringdipakai.includes(id)) {
        return 'orange accent-3'
      } else {
        return 'teal darken-1'
      }
    },
    addTindakan () {
      this.id_order = this.id_order + 1

      var self = this
      this.listOrder.forEach((val, index) => {
        if (val.item_id === this.tindakan.value) {
          self.listOrder.splice(index, 1)
          return false
        }
      })
      this.listOrder.push({
        no: this.id_order,
        item_id: this.tindakan.value,
        item_name: this.tindakan.text
      })

      this.tindakan = []

      // console.log(this.order)
    },
    paket (id) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'igd/set-nama-labor',
        { id: id },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.listOrder = results
          } else {
          }
        },
        'JSON'
      )
    },
    cariitem () {
      if (!this.searchQuery) {
        this.getData()
        return
      }
      // eslint-disable-next-line camelcase
      var array_item_group_id_hasil = []
      for (const item of this.blanko) {
        // console.log(item)
        var data = item.group_name
        var result = data
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
        if (result === true) {
          array_item_group_id_hasil.push(item.item_group_id)
        }

        if (result === false) {
          for (const force of item.list) {
            // eslint-disable-next-line camelcase
            var data_sub = force.item_name
            // eslint-disable-next-line camelcase
            var result_sub = data_sub
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
            // eslint-disable-next-line camelcase
            if (result_sub === true) {
              array_item_group_id_hasil.push(force.item_group_id)
            }
          }
          // $.each(field.list, function (x, force) {

          // })
        }
      }
      // eslint-disable-next-line camelcase, vue/no-side-effects-in-computed-properties
      // this.blanko = array_item_group_id_hasil
      console.log(array_item_group_id_hasil)
      // eslint-disable-next-line camelcase, no-unused-vars
      var first_generate = true
      var blanko = []
      var datas = this.blanko
      // eslint-disable-next-line no-unused-vars
      for (const itemhasil of datas) {
        // eslint-disable-next-line camelcase
        if (array_item_group_id_hasil.includes(itemhasil.item_group_id)) {
          blanko.push({
            item_type_id: itemhasil.item_type_id,
            item_group_id: itemhasil.item_group_id,
            // list: this.getDataSingle(item.item_group_id),
            group_name: itemhasil.group_name,
            list: itemhasil.list
          })
        }
      }

      this.blanko = blanko
    },

    simpan () {
      // alert('asdsadas')
      if (this.listOrder.length === 0) {
        errorMsg('Item Tidak Boleh Kosong Dong')
      } else if (!this.keterangan) {
        errorMsg('Keterangan Klinis Tidak Boleh Kosong')
      } else if (!this.$route.query.no_rm) {
        errorMsg('Mohon Pilih Pasien Dahulu Yaaah')
      }
      // console.log(this.folioYangDipakai)
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        reg_id: this.$route.query.registration_id,
        keterangan: this.keterangan,
        item: this.listOrder,
        sub_unit: this.$route.query.sub_unit_id,
        acc: this.checkbox,
        jenisPermintaan: this.jenisPermintaan,
        keteranganAcc: this.keteranganAcc
      }
      console.log(data)

      // eslint-disable-next-line no-undef
      $.post(baseUrl + 'igd/simpan-hasil-labor', data, (res) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = res
        if (con) {
          this.listOrder = []
          this.keterangan = ''
          this.jenisPermintaan = ''
          successMsg(msg)
        } else {
          errorMsg(msg + '')
        }
      })
    },
    getAsesmenIgd () {
      // eslint-disable-next-line no-unused-vars
      const folioId = this.$route.query.folio_id
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'igd/get-data-asesmen',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // this.disablebuttonSimpan = false

            // eslint-disable-next-line no-undef, no-unused-vars
            var data = LZString.decompressFromBase64(results.pemeriksaan)
            var dataDec = JSON.parse(data)

            const isianDokter = dataDec.isian_dokter
            if (
              !isianDokter.diagnosa_utama ||
              isianDokter.diagnosa_utama.length <= 0
            ) {
              errorMsg('DIAGNOSA UTAMA PADA ASESMEN TRIAGE TIDAK DIISI')
              return
            }

            // console.log(isianDokter)
            this.keterangan = isianDokter.diagnosa_utama
            // console.log(planning)
          } else {
            // this.disablebuttonSimpan = false
            // errorMsg(msg)
          }
        },
        'JSON'
      )
    }
  },
  watch: {
    searchTindakan (val) {
      // console.log(val)
      if (val && (!this.tindakan || this.tindakan.text !== val)) {
        apiKedua
          .get('labor/get-tindakan?q=' + val, {})
          .then((response) => (this.itemTindakan = response.data.results))
          .catch(function (error) {
            // handle error
            console.log(error)
          })
          .then(function () {
            // always executed
          })
      }
    }
  }
}
</script>
<style>
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

tr {
  padding: 0 !important;
  margin: 0 !important;
}

.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}

li ul .list-group-item {
  position: relative;
  display: block;
  padding: 0.2rem 0.01 1.25rem !important;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 4px;
}
</style>
