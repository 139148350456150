<template>
  <v-dialog v-model="showDialog" :persistent="true" max-width="1080">
    <v-card>
      <v-card-title>Form Resep Racikan</v-card-title>
      <v-card-subtitle>
        <v-btn
          v-if="negativeButton"
          color="red darken-1"
          text
          :disabled="disabledNegativeBtn"
          @click="negativeButton"
        >
          Tutup
        </v-btn>
      </v-card-subtitle>
      <v-card-text>
        <v-select
          :items="items"
          v-model="selectedRacikan"
          @change="addRacikan"
          label="Buat Resep Racikan"
          outlined
        ></v-select>

        <div v-for="(itemRacikan , r) in banyakRacikan" :key="r">
          <v-simple-table class="table table-bordered table-striped mt-1">
            <thead>
            <tr>
              <th style="vertical-align: middle">
                <v-btn @click="deleteRacikan(r)" small fab color="red" dark>
                  <v-icon x-small>mdi-close</v-icon>
                </v-btn>
              </th>
              <th colspan="3" style="vertical-align: middle" class="text-center"><b>{{ itemRacikan.id_racikan }}</b>
              </th>
            </tr>
            <tr>
              <th width="6%" style="text-align: center;vertical-align: middle">Aksi</th>
              <th style="text-align: center;vertical-align: middle" width="50%">Nama Obat</th>
              <th style="text-align: center;vertical-align: middle">Katerangan</th>
            </tr>
            </thead>
            <tbody v-for="(itemObat,o) in listObat" :key="o">
            <tr v-if="itemObat.id_racikan === itemRacikan.id_racikan">
              <td style="text-align: center;vertical-align: middle">
                <v-btn small fab color="red" dark @click="deleteobat(o)">
                  <v-icon small>mdi-close</v-icon>
                </v-btn>
              </td>
              <td style="text-align: center;vertical-align: middle">
                <AutoComplateAjax
                  :sync-value="(e) => (itemObat.value = e)"
                  class="mt-4 ml-2 mr-2"/>
                <v-text-field
                  class="mt-1 ml-2 mr-2"
                  dense
                  type="Dosis Obat"
                  label="Jumlah"
                  v-model="itemObat.dosis"
                  hint="Dosis Obat Satuannya Dituliskan Contoh : 100 mg/ atau 1/2 tab"
                  outlined
                ></v-text-field>
                <v-text-field
                  label="Satuan Obat"
                  outlined
                  dense
                  v-model="itemObat.uom"
                  class="mt-1 ml-2 mr-2"
                  required
                ></v-text-field>
              </td>
              <td style="text-align: center;vertical-align: middle">

                <v-textarea
                  rows="5"
                  class="mt-4 ml-2 mr-2"
                  outlined
                  v-model="itemObat.catatanKhusus"
                  placeholder="Keterangan Obat"
                />
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr style="text-align: center;vertical-align: middle">
              <th colspan="3">
                <v-btn fab small color="blue" @click="addObat(itemRacikan.id_racikan)" dark>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </th>
            </tr>
            </tfoot>
          </v-simple-table>
          <v-simple-table class="table table-bordered">
            <tbody>
            <tr>
              <td style="text-align: center;vertical-align: middle">
                <v-text-field
                  type="number"
                  v-model="itemRacikan.jumlahObat"
                  label="Jumlah Racikan"
                  class="mt-5 ml-2 mr-2"
                  outlined
                />
              </td>
              <td style="text-align: center;vertical-align: middle">
                <v-text-field
                  label="Signa"
                  class="mt-5 ml-2 mr-2"
                  v-model="itemRacikan.signa"

                  outlined
                />
              </td>
              <td style="text-align: center;vertical-align: middle">
                <v-text-field
                  label="Waktu Pemberian"
                  class="mt-5 ml-2 mr-2"
                  v-model="itemRacikan.dosis"

                  outlined
                />
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <div
          style="z-index: 999999"
          class="float-group btn-group-vertical"
          role="group"
          aria-label="Vertical button group"
        >
        <v-btn
          color="deep-purple darken-1"
          dark
          @click="simpan"
          large
          class="ma-2 btn-block"
        >
          SIMPAN RESEP RACIKAN
        </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import AutoComplateAjax from '../AutoCompleteAjax.vue'
// eslint-disable-next-line no-unused-vars
import { baseUrl, errorMsg, successMsg } from '@/plugins/supports'

export default {
  name: 'Dialog',
  components: { AutoComplateAjax },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {
      },
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {
      },
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },

    itemUse: {
      require: false,
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      content: '',
      filteredSuggestions: [],
      showSuggestions: false,
      selectedRacikan: '0',
      listObat: [],
      banyakRacikan: [],
      config: {
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
        ],
        height: 550
      },
      items: ['RACIKAN 1', 'RACIKAN 2', 'RACIKAN 3', 'RACIKAN 4', 'RACIKAN 5']
    }
  },
  methods: {
    handleInput () {
      if (this.content.length === 0) {
      }
    },
    addRacikan () {
      if (this.selectedRacikan !== '0') {
        // var self = this

        let isTrue = false
        this.banyakRacikan.forEach((val, index) => {
          // eslint-disable-next-line no-undef
          if (val.id_racikan === this.selectedRacikan) {
            isTrue = true
          }
        })

        if (!isTrue) {
          this.banyakRacikan.push({
            id_racikan: this.selectedRacikan,
            catataKhususRacikan: this.catatanKhusus,
            signa: '',
            jumlahObat: '',
            dosis: ''
          })
        }
      } else {
        this.banyakRacikan = []
      }
    },
    addRacikanAfter () {
      // var self = this

      this.banyakRacikan.push({
        id_racikan: 'RACIKAN 1',
        catataKhususRacikan: '',
        signa: '',
        jumlahObat: '',
        dosis: ''
      })
      this.listObat.push({
        id_racikan: 'RACIKAN 1',
        obat_id: '',
        // signa: '',
        // jumlahObat: '',
        uom_id: '',
        unit_price: '',
        qty: 0,
        uom: '',
        dosis: '',
        // eslint-disable-next-line no-undef
        catatanKhusus: ''
      })
    },
    addObat (id) {
      this.listObat.push({
        id_racikan: id,
        obat_id: '',
        // signa: '',
        // jumlahObat: '',
        uom_id: '',
        unit_price: '',
        qty: 0,
        uom: '',
        dosis: '',
        // eslint-disable-next-line no-undef
        catatanKhusus: ''
      })
    },
    deleteobat (index) {
      if (this.listObat.length === 1) {
        return true
      }
      this.listObat.splice(index, 1)
    },
    deleteRacikan (index) {
      if (this.banyakRacikan.length === 1) {
        return true
      }
      this.banyakRacikan.splice(index, 1)
    },
    simpan () {
      const strogeFormResep = 'form-resep' + this.$route.query.no_rm
      localStorage.setItem(strogeFormResep, JSON.stringify(this.fields))
      if (this.listObat === 0) {
        errorMsg('DATA OBAT TIDAK BOLEH KOSONG')
        return true
      }
      // eslint-disable-next-line no-unused-vars
      // console.log(temparray)
      // const allSame = true

      var idcArray = []
      for (const item of this.listObat) {
        // const text = item.text.split('/')
        // const icd = text[1]
        const icd =
          '<b>Nama Obat : </b><span class="text-danger">' +
          item.value.text +
          ' - </span><b>Dosis : </b><span class="text-danger">' +
          item.dosis +
          '</span></br>'
        idcArray.push(icd)
      }

      // eslint-disable-next-line no-unused-vars
      const html = '<br> <b>Ingin Membuat Resep Dengan Item Di Atas?</b>'

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: `Perhatian! Apakah Anda Yakin Ingin Membuat Resep Racikan Dengan No.RM ${this.$route.query.no_rm} ?`,
        text: 'Ingin Membuat Resep Non Racikan Ini Dengan No. Rekam Medis :',
        icon: 'warning',
        width: 980,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Buat Resep!',
        cancelButtonText: 'Tidak Jadi Batal',
        html: idcArray.join(' ') + html
      }).then((result) => {
        if (result.value) {
          const concat = {
            listRacikan: this.banyakRacikan,
            listObat: this.listObat
          }
          // eslint-disable-next-line no-undef
          const datas = LZString.compressToBase64(JSON.stringify(concat))

          const data = {
            no_rm: this.$route.query.no_rm,
            folio_id: this.$route.query.folio_id,
            registration_id: this.$route.query.registration_id,
            sub_unit_id: this.$route.query.sub_unit_id,
            data: datas
          }

          // eslint-disable-next-line no-undef
          $.post(baseUrl + 'igd/simpan-resep-racikan', data, (res) => {
            // eslint-disable-next-line no-undef,no-unused-vars
            const { con, msg, results } = res
            if (con) {
              this.banyakRacikan = []
              this.listObat = []
              const ini = this
              successMsg(msg)
              setTimeout(() => {
                ini.addRacikanAfter()
              }, 1000)
            } else {
              errorMsg(msg)
            }
          }, 'JSON')
        }
      })
      // eslint-disable-next-line no-undef

      // console.log(data)
    }

  }

}
</script>
<style scoped>

</style>
