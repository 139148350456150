<template>
  <div class="m-4">
    <h3 class="m-1 mb-2">Resume Medis Rawat Inap Pasien Lebih Dari > 6 Jam</h3>

    <v-row dense>
      <v-col cols="12" lg="8" sm="8" md="8">
        <div>
          <v-card color="teal darken-1" dark>
            <v-card-text>
              <v-row class="mt-1 mb-0">
                <v-col
                  v-for="(v, i) in master.demografi"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-1 mb-1 pt-1 pb-0"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.demografi[i] = e)"
                    :value="data.demografi[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :disabled="v.disabled"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <v-divider class="mt-3 mb-3" />
        <v-card>
          <v-card-title>Form Resume Medis Rawat Inap</v-card-title>

          <v-card-text>
            <v-row class="mt-0 mb-0" style="font-size: 12px !important">
              <v-col
                v-for="(v, i) in master.form1"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form1[i]"
                  :disabled="v.disabled"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" sm="4" md="4">
        <v-card>
          <v-card-title>History Resume Medis</v-card-title>
          <v-card-text>

            <div
              class="list-group mt-1"
              v-for="(itemresume, index) of listData"
              :key="index"
            >
              <div
                class="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-1">
                    <b color="primary-text">Dokter DPJP&nbsp;:&nbsp;</b
                    >{{ itemresume.dokterUtama }}
                  </p>
                  <small>{{ itemresume.created_at }}</small>
                </div>
                <p class="mb-1">
                  <v-btn
                    color="orange darken-2"
                    @click="cetakResumeMedis(itemresume)"
                    elevation-2
                    dark
                    medium
                    class="btn-block mt-1"
                    >Cetak Resume &nbsp;<v-icon small
                      >mdi-printer</v-icon
                    ></v-btn
                  >
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn @click="postSave" color="primary" medium class="btn-block">
          Simpan Resume Medis
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/resume_medis_rawat_inap'
import Dialog from '@/components/Dialog'
// import { apiKedua } from '../../plugins/supports'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      disabled: false,
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      loading: false,
      itemsKamar: false,
      selectedItem: '',
      master: Master,
      listData: '',
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          diagnosa_masuk: '',
          indikasi_rawat_inap: '',
          ringkasan_riwayat_penyakit: '',
          pemeriksaan_fisik: '',
          hasil_pemeriksaan_penunjang_yang_penting: '',
          diagnosa_utama: '',
          diagnosa_utama_icd10: '',
          diagnosa_sekunder1: '',
          diagnosa_sekunder1_icd10: '',
          diagnosa_sekunder2: '',
          diagnosa_sekunder2_icd10: '',
          diagnosa_sekunder3: '',
          diagnosa_sekunder3_icd10: '',
          diagnosa_sekunder4: '',
          diagnosa_sekunder4_icd10: '',
          diagnosa_sekunder5: '',
          diagnosa_sekunder5_icd10: '',
          tindakan_prosedur_operasi1: '',
          tindakan_prosedur_operasi1_icd9: '',
          tindakan_prosedur_operasi2: '',
          tindakan_prosedur_operasi2_icd9: '',
          tindakan_prosedur_operasi3: '',
          tindakan_prosedur_operasi3_icd9: '',
          terapi_selama_di_rs: '',
          alergi_obat: '',
          terapi_pulang: '',
          diet: '',
          prognosa: '',
          kondisi_pasien_saat_pulang: '',
          cara_keluar_rumah_sakit: '',
          rencana_tindak_lanjut: '',
          rencana_tindak_lanjut_tanggal: '',
          poliklinik_yang_dituju: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (!this.$route.query.no_rm) {
      errorMsg('PILIH DAHULU PASIENNYA')
      this.$router.push({
        name: 'DataPasienRawatInap'
      })
    }
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
      this.getListResumeMedis()
      setTimeout(() => {
        this.getMedisAsesment()
        this.getSep()
      }, 150)
    }
  },
  methods: {
    fetchItems () {

    },
    cetakResumeMedis (VALUE) {
      window
        .open(
          baseUrlEmr +
            `laporan-medis/resume-medis-rawat-inap?params=${window.btoa(
              VALUE.id_resume_rawat_inap
            )}`,
          '_blank'
        )
        .focus()
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    postSave () {
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Resume Medis Rawat Inap IGD'
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-resume-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.getListResumeMedis()
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getAssemen (folioId) {
      var type = 'Resume Medis Rawat Inap IGD'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-resume-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    },
    getListResumeMedis () {
      const norm = this.$route.query.no_rm
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-resume-rawat-inap-list',
        {
          rm: norm,
          jenis: 'Resume Medis Rawat Inap IGD'
        },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            const listData = []
            for (const item of results) {
              // idcArray.push(item.text)
              listData.push({
                id_resume_rawat_inap: item.id_resume_rawat_inap,
                dokterUtama: item.dokterUtama,
                created_at: item.created_at
              })

              console.log(item)
            }
            this.listData = listData
          }
        }
      )
    },
    getMedisAsesment () {
      const folioid = this.$route.query.folio_id
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-asesmen-rawat-inap-to-append',
        { folio_id: folioid },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            // const listData = []
            const data = results

            const pemeriksaan = JSON.parse(data.pemeriksaan)
            const asesmentDignosaMasuk = pemeriksaan.form4.p3
            const riwayatPenyakitSekarang =
              pemeriksaan.form1.p2 + '\n' + pemeriksaan.form1.p3
            console.log(riwayatPenyakitSekarang)
            // const

            var diagnosaMasuk = this.data.form1.diagnosa_masuk
            if (typeof diagnosaMasuk !== 'undefined') {
              var brackets = diagnosaMasuk.match(/\([^)]*\)/g)

              var appnedKeluhanUtamaMasuk =
                '(# : ' + asesmentDignosaMasuk + ')'
              // console.log(appnedKeluhanUtamaMasuk)

              this.item_ke = null
              if (brackets !== null) {
                brackets.forEach((item, index) => {
                  if (item.includes('(# : ')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.diagnosa_masuk =
                    this.data.form1.diagnosa_masuk.replace(
                      brackets[this.item_ke],
                      appnedKeluhanUtamaMasuk
                    )
                } else {
                  this.data.form1.diagnosa_masuk = this.appendTechnique(
                    this.data.form1.diagnosa_masuk,
                    appnedKeluhanUtamaMasuk
                  )
                }
              } else {
                this.data.form1.diagnosa_masuk = this.appendTechnique(
                  this.data.form1.diagnosa_masuk,
                  appnedKeluhanUtamaMasuk
                )
              }
            } else {
              var appendKeluhanUtamaMasukS =
                '(# : ' + asesmentDignosaMasuk + ')'
              this.data.form1.diagnosa_masuk = appendKeluhanUtamaMasukS
            }

            // riwayat penyakit skrg
            var riwayatPenyakit = this.data.form1.ringkasan_riwayat_penyakit
            if (typeof riwayatPenyakit !== 'undefined') {
              var bracketsriwayatPenyakit = riwayatPenyakit.match(/\([^)]*\)/g)

              var appendRiwatPenyakit = '(# : ' + riwayatPenyakitSekarang + ')'
              // console.log(appendRiwatPenyakit)

              this.item_ke = null
              if (bracketsriwayatPenyakit !== null) {
                bracketsriwayatPenyakit.forEach((item, index) => {
                  if (item.includes('(# : ')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.ringkasan_riwayat_penyakit =
                    this.data.form1.ringkasan_riwayat_penyakit.replace(
                      bracketsriwayatPenyakit[this.item_ke],
                      appendRiwatPenyakit
                    )
                } else {
                  this.data.form1.ringkasan_riwayat_penyakit =
                    this.appendTechnique(
                      this.data.form1.ringkasan_riwayat_penyakit,
                      appendRiwatPenyakit
                    )
                }
              } else {
                this.data.form1.ringkasan_riwayat_penyakit =
                  this.appendTechnique(
                    this.data.form1.ringkasan_riwayat_penyakit,
                    appendRiwatPenyakit
                  )
              }
            } else {
              var appendRiwatPenyakitA =
                '(# : ' + riwayatPenyakitSekarang + ')'
              this.data.form1.ringkasan_riwayat_penyakit = appendRiwatPenyakitA
            }
          }
        }
      )
    },
    appendTechnique (satu, dua) {
      // var a = 'how you are'
      if (satu.indexOf(dua) > -1) {
        // If already contained
        return satu
      }
      if (satu !== '' && satu !== dua) {
        // If not empty and not the same input as current
        return satu + '\n' + dua
      } else {
        return dua
      }
    },
    getSep () {
      const regid = this.$route.query.registration_id
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-sep-rawat-inap',
        { reg_id: regid },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            // const listData = []
            // for (const item of results) {
            //   // idcArray.push(item.text)
            //   listData.push({
            //     id_resume_rawat_inap: item.id_resume_rawat_inap,
            //     dokterUtama: item.dokterUtama,
            //     created_at: item.created_at
            //   })
            //   console.log(item)
            // }
            // this.listData = listData
          }
        }
      )
    }
  },
  watch: {
    'data.form1.poliklinik_yang_dituju' (a, b) {
      console.log(a)

      if (
        this.data.form1.rencana_tindak_lanjut.text ===
        'Kontrol Ulang Ke Poliklinik,'
      ) {
        this.data.form1.rencana_tindak_lanjut.value = a.text
      }

      const subUnitId = a.id
      const tanggal = this.data.form1.rencana_tindak_lanjut_tanggal
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-jadwal-dokter',
        { sub_unit: subUnitId, tanggal: tanggal },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.disabled = false
          } else {
            errorMsg(msg)
            this.disabled = true
          }
        },
        'JSON'
      )
    },
    'data.form1.rencana_tindak_lanjut_tanggal' (a, b) {
      // console.log(a)

      const subUnitId = this.data.form1.poliklinik_yang_dituju.id
      const tanggal = this.data.form1.rencana_tindak_lanjut_tanggal
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-jadwal-dokter',
        { sub_unit: subUnitId, tanggal: tanggal },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.disabled = false
          } else {
            errorMsg(msg)
            this.disabled = true
          }
        },
        'JSON'
      )
    },
    'data.form1.diagnosa_utama_icd10' (a, b) {
      // console.log(a + '1111')
      // console.log(this.data.isian_dokter.diagnosa_utama)
      // eslint-disable-next-line no-unused-vars
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }
        // console.log(idcArray)
        // eslint-disable-next-line no-unused-vars
        var diagnosa = idcArray.join(',')
        // console.log(diagnosa)
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.form1.diagnosa_utama
        // console.log(data + ' anak')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          console.log(brackets + 'style')
          // console.log(brackets)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          // console.log(appendKeSubjektifDokter + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.form1.diagnosa_utama =
                this.data.form1.diagnosa_utama.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.form1.diagnosa_utama = this.appendTechnique(
                this.data.form1.diagnosa_utama,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.form1.diagnosa_utama = this.appendTechnique(
              this.data.form1.diagnosa_utama,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.diagnosa_utama = appendKeSubjektifDokters
        }
      }
    },
    'data.form1.diagnosa_sekunder1_icd10' (a, b) {
      // console.log(a + '1111')
      // console.log(this.data.isian_dokter.diagnosa_utama)
      // eslint-disable-next-line no-unused-vars
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }
        // console.log(idcArray)
        // eslint-disable-next-line no-unused-vars
        var diagnosa = idcArray.join(',')
        // console.log(diagnosa)
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.form1.diagnosa_sekunder1
        // console.log(data + ' anak')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          console.log(brackets + 'style')
          // console.log(brackets)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          // console.log(appendKeSubjektifDokter + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.form1.diagnosa_sekunder1 =
                this.data.form1.diagnosa_sekunder1.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.form1.diagnosa_sekunder1 = this.appendTechnique(
                this.data.form1.diagnosa_sekunder1,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.form1.diagnosa_sekunder1 = this.appendTechnique(
              this.data.form1.diagnosa_sekunder1,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.diagnosa_sekunder1 = appendKeSubjektifDokters
        }
      }
    },
    'data.form1.diagnosa_sekunder2_icd10' (a, b) {
      // console.log(a + '1111')
      // console.log(this.data.isian_dokter.diagnosa_utama)
      // eslint-disable-next-line no-unused-vars
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }
        // console.log(idcArray)
        // eslint-disable-next-line no-unused-vars
        var diagnosa = idcArray.join(',')
        // console.log(diagnosa)
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.form1.diagnosa_sekunder2
        // console.log(data + ' anak')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          console.log(brackets + 'style')
          // console.log(brackets)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          // console.log(appendKeSubjektifDokter + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.form1.diagnosa_sekunder2 =
                this.data.form1.diagnosa_sekunder2.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.form1.diagnosa_sekunder2 = this.appendTechnique(
                this.data.form1.diagnosa_sekunder2,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.form1.diagnosa_sekunder2 = this.appendTechnique(
              this.data.form1.diagnosa_sekunder2,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.diagnosa_sekunder2 = appendKeSubjektifDokters
        }
      }
    }
  }
}
</script>

<style scoped>
.float-group {
  position: fixed;
  bottom: 10%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
