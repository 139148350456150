<template>
  <v-container class="mb-5 mt-1">
    <v-row>
      <v-col cols="12" lg="4" md="4" sm="4">
        <div class="mt-2" v-if="data.demografi.notes">
          <v-alert shaped prominent type="error">
            Catatan IGD : <u>{{ data.demografi.notes }}</u>
          </v-alert>
        </div>
        <v-btn dark class="ma-1" @click="laboratorium()" color="indigo">
          Laboraorium <v-icon small>mdi-plus</v-icon>
        </v-btn>
        <v-btn dark @click="radiologi()" class="ma-1" color="blue">
          Radiologi <v-icon small>mdi-plus</v-icon>
        </v-btn>

        <v-card color="teal darken-1 mt-2" dark>
          <v-card-title>Demografi Pasien</v-card-title>

          <v-card-text>
            <v-row class="mt-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8" md="8" sm="8">
        <h5>HISTORY ORDERAN</h5>
        <!--        {{listOrderan}}-->
        <v-progress-linear
          v-show="progress"
          color="primary"
          indeterminate
          rounded
          height="6"
        />
        <table class="table table-bordered table-sm" width="100%">
          <thead>
            <tr class="table-info">
              <th
                class="text-center"
                style="vertical-align: middle"
                width="35%"
              >
                NO. ORDERAN
              </th>
              <th class="text-center" style="vertical-align: middle">
                ITEM PEMERIKSAAN
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="table-warning"
              v-for="(itemorderan, indexorder) of listOrderan"
              :key="indexorder"
            >
              <td style="vertical-align: middle">
                <b>{{ itemorderan.no_order_penunjang }}</b
                ><br />
                <b>Tgl/Jam Order : </b>
                <u>{{ itemorderan.tgl_order }}</u
                ><br />
                <b>Diagnosa/Keterangan Klinis :</b>
                {{ itemorderan.keterangan }}
                <br />
                <div v-if="itemorderan.jenis == 'IGD LABOR'">
                  <b>Jenis Orderan : </b
                  ><span class="primary--text">Laboratorium</span>
                </div>
                <div v-if="itemorderan.jenis == 'IGD RADIOLOGI'">
                  Tujuan Radiologi :
                  <span class="primary--text">{{
                    itemorderan.diambil_di
                  }}</span>
                </div>

                <br />
                <div v-if="itemorderan.jenis == 'IGD LABOR'">
                  <v-btn
                    v-if="itemorderan.folio_lab"
                    color="green darken-1"
                    dark
                    small
                    @click="liatHasilLabor(itemorderan.folio_lab)"
                    class="btn-block mt-1"
                    >Hasil Penunjang</v-btn
                  >
                  <v-divider></v-divider>

                  <v-btn
                    color="red darken-2"
                    class="mt-1 btn-block"
                    dark
                    @click="editPenunjangLaborIgd(itemorderan)"
                    small
                    >Edit <v-icon right small>mdi-pencil</v-icon></v-btn
                  >
                </div>
                <div v-if="itemorderan.jenis == 'IGD RADIOLOGI'">
                  <v-btn
                    v-if="itemorderan.folio_lab"
                    color="orange darken-1"
                    dark
                    small
                    @click="liatHasilRadiologi(itemorderan.folio_lab)"
                    class="btn-block mt-1"
                    >Hasil Penunjang</v-btn
                  >
                  <v-divider></v-divider>

                  <v-btn
                    color="red darken-2"
                    class="mt-1 btn-block"
                    dark
                    @click="editPenunjangRadiologiIgd(itemorderan)"
                    small
                    >Edit <v-icon right small>mdi-pencil</v-icon></v-btn
                  >
                </div>
                <div v-else></div>
              </td>
              <td style="vertical-align: middle" class="text-center">
                <v-chip
                  color="primary"
                  label
                  dark
                  class="ma-1"
                  text-color="white"
                  v-for="(
                    itemorderpenunjang, indexitemorderpenunjang
                  ) of itemorderan.item_order"
                  :key="indexitemorderpenunjang"
                >
                  <v-icon left> mdi-list-box </v-icon>
                  Nama Item :&nbsp;<b
                    ><u>{{ itemorderpenunjang.item_name }}</u></b
                  >
                </v-chip>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="showDialogPenunjang" max-width="1280px">
        <v-card>
          <v-card-title class="headline"> Hasil Penunjang </v-card-title>
          <v-card-text>
            <div id="hasil"></div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editShowLabor" max-width="560px">
        <v-card>
          <v-card-title class="headline"> Edit Laboratorium </v-card-title>
          <v-card-text>
            <p>
              <b>Nama Pasien : </b> {{ data.demografi.nama_pasien }}
              <br />
              <b>No.Orderan : </b> {{ itemEdit.no_order_penunjang }}
              <br />
              <b>Tgl & Jam Orderan : </b> {{ itemEdit.tgl_order }}
            </p>
            <v-btn
              class="mt-1 mb-1 btn-block"
              @click="updateitemlabor()"
              color="green darken-1"
              medium
              dark
              >Update Item</v-btn
            >
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(itemedit, indexedit) of listEditItem"
                :key="indexedit"
              >
                <v-btn
                  @click="deleteTindakan(indexedit)"
                  fab
                  color="red darken-4"
                  small
                  dark
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
                &nbsp; {{ itemedit.item_name }}
              </li>

              <li class="list-group-item">
                <v-autocomplete
                  return-object
                  outlined
                  class="mt-2"
                  dense
                  item-text="text"
                  item-value="value"
                  v-model="tindakan"
                  :items="getTindakan"
                  :search-input.sync="searchTindakan"
                  @change="addTindakan()"
                  label="Cari Nama Item Labor"
                  placeholder="Cari Nama Item Laboratorium"
                ></v-autocomplete>
              </li>
              <li class="list-group-item">
                <v-textarea
                  label="Keterangan Klinis"
                  class="mt-2"
                  outlined
                  v-model="itemEdit.keterangan"
                ></v-textarea>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editShowRadiologi" max-width="560px">
        <v-card>
          <v-card-title class="headline"> Edit Radiologi </v-card-title>
          <v-card-text>
            <p>
              <b>Nama Pasien : </b> {{ data.demografi.nama_pasien }}
              <br />
              <b>No.Orderan : </b> {{ itemEdit.no_order_penunjang }}
              <br />
              <b>Tgl & Jam Orderan : </b> {{ itemEdit.tgl_order }}
            </p>
            <v-btn
              class="mt-1 mb-1 btn-block"
              @click="updateitemlabor()"
              color="green darken-1"
              medium
              dark
              >Update Item</v-btn
            >
            <ul class="list-group">
              <li
                class="list-group-item"
                v-for="(itemedit, indexedit) of listEditItem"
                :key="indexedit"
              >
                <v-btn
                  @click="deleteTindakan(indexedit)"
                  fab
                  color="red darken-4"
                  small
                  dark
                  ><v-icon small>mdi-delete</v-icon></v-btn
                >
                &nbsp; {{ itemedit.item_name }}
              </li>

              <li class="list-group-item">
                <v-autocomplete
                  return-object
                  outlined
                  class="mt-2"
                  dense
                  item-text="text"
                  item-value="value"
                  v-model="tindakan"
                  :items="getTindakan"
                  :search-input.sync="searchTindakanRadiologi"
                  @change="addTindakan()"
                  label="Cari Nama Item Radiologi"
                  placeholder="Cari Nama Item Radiologi"
                ></v-autocomplete>
              </li>
              <li class="list-group-item">
                <v-textarea
                  label="Keterangan Klinis"
                  class="mt-2"
                  outlined
                  v-model="itemEdit.keterangan"
                ></v-textarea>
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <Laboratorium
      :show-dialog="showDialogLabaratorium"
      ref="getLabor"
      :negative-button="dialogActionNBtnLKirimLabaratorium"
      :disabled-negative-btn="dialogDisableNBtnKirimLabaratorium"
    />
    <Radiologi
      :show-dialog="showDialogRadiologi"
      ref="getRadiologi"
      :negative-button="dialogActionNBtnLKirimRadiologi"
      :disabled-negative-btn="dialogDisableNBtnKirimRadiologi"
    />
  </v-container>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/demografi-all'
import Dialog from '@/components/Dialog'
// eslint-disable-next-line no-unused-vars
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  isEmpty
} from '@/plugins/supports'

import Laboratorium from './form-penunjang/Laboratorium.vue'
import Radiologi from './form-penunjang/Radiologi.vue'

export default {
  computed: {
    getTindakan () {
      return this.itemTindakan.map((v) => ({
        text: v.item_name,
        value: v.item_id
      }))
    }
  },
  watch: {
    searchTindakan (val) {
      // console.log(val)
      if (val && (!this.tindakan || this.tindakan.text !== val)) {
        apiKedua
          .get('labor/get-tindakan?q=' + val, {})
          .then((response) => (this.itemTindakan = response.data.results))
          .catch(function (error) {
            // handle error
            console.log(error)
          })
          .then(function () {
            // always executed
          })
      }
    },
    searchTindakanRadiologi (val) {
      // console.log(val)
      if (val && (!this.tindakan || this.tindakan.text !== val)) {
        apiKedua
          .get('radiologi/get-tindakan?q=' + val, {})
          .then((response) => (this.itemTindakan = response.data.results))
          .catch(function (error) {
            // handle error
            console.log(error)
          })
          .then(function () {
            // always executed
          })
      }
    }
  },
  components: {
    Laboratorium, // eslint-disable-next-line vue/no-unused-components
    Radiologi, // eslint-disable-next-line vue/no-unused-components
    SmartWidget,
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog
  },
  data () {
    return {
      url_emr: 'http://emr.rs-syafira.com/',
      permintaans_page: 1,
      showDialogPenunjang: false,
      editShowLabor: false,
      itemTindakan: [],
      searchTindakan: '',
      searchTindakanRadiologi: '',
      tindakan: [],
      editShowRadiologi: false,
      itemEdit: '',
      progress: false,
      listEditItem: [],
      regid: this.$route.query.registration_id,
      fields: [{ value: '', catatan: '', jumlah: '', signa: '', waktu: '' }],

      // autocompleteData: ['Option 1', 'Option 2', 'Option 3'],
      autocompleteData: [],

      isActive: false,
      items: ['Rawat Bersama', 'Ahli Rawat', 'Konsul'],
      master: Master,
      user: JSON.parse(localStorage.getItem('user')),
      listOrderan: [],
      loading: false,

      // DIALOG LABOR
      showDialogLabaratorium: false,
      dialogDisableNBtnKirimLabaratorium: false,
      dialogActionNBtnLKirimLabaratorium: () => {
        this.showDialogLabaratorium = false
        this.getdatapenunjang()
      },

      // DIALOG RADIOLOGI
      showDialogRadiologi: false,
      dialogDisableNBtnKirimRadiologi: false,
      dialogActionNBtnLKirimRadiologi: () => {
        this.showDialogRadiologi = false
        this.getdatapenunjang()
      },
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      },
      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => this.postSave()
    }
  },
  mounted () {
    // this.getDataListKonsultasi()
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.pegawai = JSON.parse(localStorage.getItem('user'))
      this.getDataPasien(this.$route.query.no_rm)
      this.getdatapenunjang()
    }
  },
  methods: {
    editPenunjangLaborIgd (RESULTS) {
      console.log(RESULTS)
      if (RESULTS.status !== 'DIORDER') {
        errorMsg('STATUS SUDAH DIKERJAKAN OLEH ADMIN')
        return
      }
      this.editShowLabor = true
      this.itemEdit = RESULTS
      this.listEditItem = RESULTS.item_order
    },
    editPenunjangRadiologiIgd (RESULTS) {
      console.log(RESULTS)

      if (RESULTS.status !== 'DIORDER') {
        errorMsg('STATUS SUDAH DIKERJAKAN OLEH ADMIN')
        return
      }
      this.editShowRadiologi = true
      this.itemEdit = RESULTS
      this.listEditItem = RESULTS.item_order
    },
    liatHasilRadiologi (folioId) {
      this.showDialogPenunjang = true
      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlV2 + 'rme/radiologi-view?folio_id=' + folioId,
        // eslint-disable-next-line no-undef
        type: 'GET',
        success: (result) => {
          // // console.log(result);
          // eslint-disable-next-line no-undef
          $('#hasil').html(result)
          // // eslint-disable-next-line no-undef
          // $('#mymodal2').modal({ show: true })
        }
      })
    },
    updateitemlabor () {
      const data = {
        itemedit: this.listEditItem,
        id_order_penunjang: this.itemEdit.id_order_penunjang
      }
      console.log(data)

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'labor/update-item-orderan',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            successMsg(msg)
            this.getdatapenunjang()
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    addTindakan () {
      this.id_order = this.id_order + 1

      var self = this
      this.listEditItem.forEach((val, index) => {
        if (val.item_id === this.tindakan.value) {
          self.listEditItem.splice(index, 1)
          return false
        }
      })
      this.listEditItem.push({
        no: this.id_order,
        item_id: this.tindakan.value,
        item_name: this.tindakan.text
      })

      this.tindakan = []

      // console.log(this.order)
    },
    liatHasilLabor (folioid) {
      this.showDialogPenunjang = true

      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlV2 + 'rme/labor-view?folio_id=' + folioid,
        // eslint-disable-next-line no-undef
        type: 'GET',
        success: (result) => {
          // // console.log(result);
          // eslint-disable-next-line no-undef
          $('#hasil').html(result)
          // // eslint-disable-next-line no-undef
          // $('#mymodal2').modal({ show: true })
        }
      })
    },
    radiologi () {
      // return radiologi
      this.showDialogRadiologi = true
      this.$refs.getRadiologi.getData()
    },
    laboratorium () {
      this.showDialogLabaratorium = true
      this.$refs.getLabor.getData()
      this.$refs.getLabor.getAsesmenIgd()
      // return laboratorium
    },

    getdatapenunjang () {
      this.progress = true
      // eslint-disable-next-line no-unused-vars
      const id = this.$route.query.registration_id

      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'igd/get-data-penunjang?id=' + id,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            setTimeout(() => {
              this.progress = false
            }, '100')

            var listorder = []

            results.forEach((obj) => {
              // eslint-disable-next-line no-undef
              const itemorder = LZString.decompressFromBase64(obj.item_order)
              const itemorderpase = JSON.parse(itemorder)

              listorder.push({
                id_order_penunjang: obj.id_order_penunjang,
                no_order_penunjang: obj.no_order_penunjang,
                no_reg: obj.no_reg,
                tgl_order: obj.tgl_order,
                keterangan: obj.keterangan,
                folio_lab: obj.folio_lab,
                status: obj.status,
                diambil_di: obj.diambil_di,
                jenis: obj.jenis,
                item_order: itemorderpase
              })
            })
            this.listOrderan = listorder
          } else {
            this.progress = false
          }
        },
        'JSON'
      )
    },
    deleteTindakan (v) {
      this.listEditItem.splice(v, 1)
    },
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          const date = new Date(response.data.results.tanggal_lahir)

          const day = date.toLocaleString('default', { day: '2-digit' })
          const month = date.toLocaleString('default', { month: 'short' })
          const year = date.toLocaleString('default', { year: 'numeric' })
          // console.log(response.data.results.tanggal_lahir)
          const tglLahir = day + '/' + month + '/' + year
          this.data.demografi.umur = this.umur(tglLahir)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    umur (tglLahir) {
      var dob = new Date(tglLahir)
      var dobYear = dob.getYear()
      var dobMonth = dob.getMonth()
      var dobDate = dob.getDate()
      var yearAge = ''
      // get the current date from the system
      var now = new Date()
      // extract the year, month, and date from current date
      var currentYear = now.getYear()
      var currentMonth = now.getMonth()
      var currentDate = now.getDate()

      // declare a variable to collect the age in year, month, and days
      var age = {}
      var ageString = ''

      // get years
      yearAge = currentYear - dobYear

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        var monthAge = currentMonth - dobMonth
      } else {
        yearAge--
        // eslint-disable-next-line no-redeclare
        var monthAge = 12 + currentMonth - dobMonth
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate
      } else {
        monthAge--
        // eslint-disable-next-line no-redeclare
        var dateAge = 31 + currentDate - dobDate

        if (monthAge < 0) {
          monthAge = 11
          yearAge--
        }
      }
      // group the age in a single variable
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      } else if (age.years === 0 && age.months === 0 && age.days > 0) {
        ageString = ' ' + age.days + ' Hari'
        // eslint-disable-next-line brace-style
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months === 0 && age.days === 0) {
        ageString = age.years + ' Tahun. Happy Birthday!!'
      } else if (age.years > 0 && age.months > 0 && age.days === 0) {
        ageString = age.years + ' Tahun dan ' + age.months + ' Bulan.'
      } else if (age.years === 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + ' Bulan dan ' + age.days + ' Hari.'
      } else if (age.years > 0 && age.months === 0 && age.days > 0) {
        ageString = age.years + ' Tahun, dan' + age.days + ' Hari.'
      } else if (age.years === 0 && age.months > 0 && age.days === 0) {
        ageString = age.months + ' Bulan.'
        // eslint-disable-next-line brace-style
      }
      // when current date is same as dob(date of birth)
      else {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      }

      // console.log(ageString)
      return ageString
    }
  }
}
</script>
<style scoped></style>
