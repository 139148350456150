export default {
  demografi: {
    nama_pasien: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Nama Pasien'
    },
    no_rekam_medis: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'No. Rekam Medis'
    },
    tanggal_lahir: {
      widget: 'wdatenormal',
      data: null,
      col: 3,
      label: 'Tanggal Lahir'
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 3
    },
    umur: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Umur'
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No.Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No.Reg',
      col: 3
    },
    kartu_anggota: {
      widget: 'wtext',
      data: null,
      label: 'No.Kartu',
      col: 6
    },
    jenis_kelamin: {
      widget: 'wradio',
      label: 'Jenis Kelamin',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 6
    }
  },
  kunjungan: {
    status_kunjungan: {
      widget: 'wradio',
      label: 'Status Kunjungan',

      data: [
        { text: 'Baru', value: 'Baru' },
        { text: 'Lama', value: 'Lama' }
      ],
      col: 4
    },
    cara_datang: {
      widget: 'wradio',
      label: 'Cara Datang',
      data: [
        { text: 'Sendiri', value: 'Sendiri' },
        { text: 'Ambulance', value: 'Ambulance' },
        { text: 'Poli', value: 'Poli' },
        { text: 'Asal Rujukan', value: null, field: 'auto' },
        { text: 'Diantar Polisi', value: 'Diantar Polisi' }
      ],
      col: 8
    },
    datang_di_IGD_tanggal: {
      widget: 'wdatenormal',
      label: 'Datang di IGD Tanggal',
      data: null,
      col: 6
    },
    jam: {
      widget: 'wtime',
      data: null,
      label: 'Jam',
      col: 6,
      property: { attrs: { suffix: 'WIB', type: 'number' } }
    }
  },
  jenis_kasus: {
    kasus: {
      widget: 'wradio',
      label: 'Jenis Kasus',
      data: [
        { text: 'Trauma', value: 'Trauma' },
        { text: 'Non Trauma', value: 'Non Trauma' },
        { text: 'Obstetri', value: 'Obstetri' }
      ],
      col: 4
    },
    trauma_penyebab_utama: {
      widget: 'wradio',
      label: 'Trauma Penyebab Utama',

      data: [
        { text: 'HT', value: 'HT' },
        { text: 'Kerja', value: 'Kerja' },
        { text: 'Kekerasan', value: 'Kekerasan' },
        { text: 'Intoksikasi', value: 'Intoksikasi' },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ],
      col: 8
    }
  },
  triage_table: {
    widget: 'wtext',
    property: { attrs: { type: 'number' } },
    data: [
      {
        text: 'skala1',
        value: 'skala1',
        dataCols: ['Skala 1', 'Resusitasi', 'Segara'],
        color: '#C62828'
      },
      {
        text: 'skala2',
        value: 'skala2',
        dataCols: ['Skala 2', 'Emergency/Gawat Darurat', '10 Menit'],
        color: '#FFEA00'
      },
      {
        text: 'skala3',
        value: 'skala3',
        dataCols: ['Skala 3', 'Urgent/Darurat', '30 Menit'],
        color: '#9CCC65'
      },
      {
        text: 'skala4',
        value: 'skala4',
        dataCols: ['Skala 4', 'Semi Darurat', '60 Menit'],
        color: '#039BE5'
      },
      {
        text: 'skala5',
        value: 'skala5',
        dataCols: ['Skala 5', 'Tidak Darurat', '120 Menit']
      }
    ]
  },
  tanda_vital: {
    keadaan_umum: {
      label: 'Keadaan Umum',
      widget: 'wtext',
      col: 4,
      data: null
    },
    kesadaraan: {
      label: 'Kesadaraan',
      widget: 'wtext',
      col: 4,
      data: null
    },
    GCS: {
      label: 'GCS',
      widget: 'wtext',
      col: 4,
      data: null
    },
    E: {
      label: 'E',
      widget: 'wtext',
      col: 4,
      data: null
    },
    M: {
      label: 'M',
      widget: 'wtext',
      col: 4,
      data: null
    },
    V: {
      label: 'V',
      widget: 'wtext',
      col: 4,
      data: null
    },
    TD: {
      label: 'TD',
      widget: 'wtext',
      col: 3,
      data: null
    },
    Nadi: {
      label: 'Nadi',
      widget: 'wtext',
      col: 3,
      data: null
    },
    RR: {
      label: 'RR',
      widget: 'wtext',
      col: 3,
      data: null
    },
    reflek_cahaya: {
      label: 'Reflek Cahaya',
      widget: 'wtext',
      col: 3,
      data: null
    },
    pupil_mata: {
      label: 'Pupil Mata',
      widget: 'wtext',
      col: 3,
      data: null
    },
    suhu: {
      label: 'Suhu',
      widget: 'wtext',
      col: 3,
      data: null
    },
    saturasi_O2: {
      label: 'Saturasi O2',
      widget: 'wtext',
      col: 3,
      data: null
    },
    jenis_pilihan: {
      widget: 'wautocomplatekeluhan',
      label: 'Pilih Jenis keluhan',
      data: null,
      col: 12
    },
    anamnesis: {
      widget: 'wtextarea',
      label: 'Anemnesis ( Keluhan utama, riwayat penyakit sekarang)',
      data: null,
      col: 12
    }
  },
  penyakit_dahulu: {
    riwayat_penyakit_terdahulu: {
      label: 'Riwayat Penyakit Terdahulu',
      widget: 'wradio',
      data: [
        { text: 'HT', value: 'HT' },
        { text: 'DM', value: 'DM' },
        { text: 'PJK', value: 'PJK' },
        { text: 'Asthma', value: 'Asthma' },
        { text: 'Tidak Ada', value: 'Tidak Ada' },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    },
    alergi: {
      widget: 'wradio',
      label: 'Alergi',
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Tidak Tahu', value: 'Tidak Tahu' },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ],
      col: 6
    }
  },
  skala_nyeri: {
    widget: 'wtext',
    label: 'Skala Nyeri',
    data: null,
    col: 6,
    property: { attrs: { suffix: '°C' } }
  },
  inputan_petugas_pendaftaran: {
    datang_di_IGD_tanggal: {
      widget: 'wdatenormal',
      label: 'Datang di IGD Tanggal',
      data: null,
      col: 6
    },
    jam: {
      widget: 'wtime',
      data: null,
      label: 'Jam',
      col: 6,
      property: { attrs: { suffix: 'WIB', type: 'string' } }
    },
    pekerjaan: {
      widget: 'wradio',
      label: 'Pekerjaan',
      data: [
        {
          text: 'TNI/POLRI',
          value: 'TNI/POLRI'
        },
        {
          text: 'PNS',
          value: 'PNS'
        },
        {
          text: 'SWASTA',
          value: 'SWASTA'
        },
        {
          text: 'ERT',
          value: 'ERT'
        },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ]
    },
    penghasilan: {
      widget: 'wradio',
      label: 'Penghasilan',
      data: [
        {
          text: 'tidak ada',
          value: 'tidak ada'
        },
        {
          text: '< 1 juta',
          value: '< 1 juta'
        },
        {
          text: '1 - 2,9 juta',
          value: '1 - 2,9 juta'
        },
        {
          text: '3-4,9 juta',
          value: '3-4,9 juta'
        },
        {
          text: '5-9,9 juta',
          value: '5-9,9 juta'
        },
        {
          text: '10-14,9 juta',
          value: '10-14,9 juta'
        },
        {
          text: '>15 juta',
          value: '>15 juta'
        }
      ]
    },
    agama: {
      widget: 'wradio',
      label: 'Agama',
      data: [
        {
          text: 'islam',
          value: 'islam'
        },
        {
          text: 'katolik',
          value: 'katolik'
        },
        {
          text: 'kristen',
          value: 'kristen'
        },
        {
          text: 'hindu',
          value: 'hindu'
        },
        {
          text: 'budha',
          value: 'budha'
        },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ]
    },
    lsuku_budaya: {
      label: 'Suku Budaya / Nilai Kepercayaan',
      widget: 'wlabelkiri',
      col: 5,
      data: [],
      value: null
    },
    suku_budaya: {
      widget: 'wtext',
      col: 7,
      label: ' ',
      value: null
    },
    pendidikan: {
      widget: 'wradio',
      data: [
        {
          text: 'SD',
          value: 'SD'
        },
        {
          text: 'SMP',
          value: 'SMP'
        },
        {
          text: 'SMA',
          value: 'SMA'
        },
        {
          text: 'akademi',
          value: 'akademi'
        },
        {
          text: 'sarjana',
          value: 'sarjana'
        },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ]
    },
    bahasa: {
      widget: 'wradio',
      data: [
        {
          text: 'indonesia',
          value: 'indonesia'
        },
        {
          text: 'asing',
          value: 'asing'
        },
        {
          text: 'daerah',
          value: 'daerah'
        },
        {
          text: 'penerjemah',
          value: 'penerjemah'
        }
      ]
    }
  },
  inputan_perawat: {
    tanggal: {
      widget: 'wdatenormal',
      data: null,
      label: 'Tanggal',
      col: 3
    },
    jam: {
      widget: 'wtime',
      data: null,
      label: 'Jam',
      col: 3,
      property: { attrs: { suffix: 'WIB', type: 'number' } }
    },
    di: {
      widget: 'wtext',
      label: 'Di',
      data: null,
      col: 6
    },
    GCS: {
      widget: 'wtext',
      col: 6,
      label: 'GCS',
      data: null
    },
    TD: {
      widget: 'wtext',
      col: 6,
      label: 'TD',
      data: null,
      property: { attrs: { suffix: 'mmHG', type: 'number' } }
    },
    n: {
      widget: 'wtext',
      col: 3,
      label: 'N',
      data: null,
      property: { attrs: { suffix: 'x/mnt', type: 'number' } }
    },
    s: {
      widget: 'wtext',
      col: 3,
      label: 'S',

      data: null,
      property: { attrs: { suffix: 'oC', type: 'number' } }
    },
    rR: {
      widget: 'wtext',
      col: 3,
      label: 'RR',

      data: null,
      property: { attrs: { suffix: 'x/mnt', type: 'number' } }
    },
    sPO2: {
      widget: 'wtext',
      col: 3,
      label: 'SPO2',
      data: null,
      property: { attrs: { suffix: '%', type: 'number' } }
    },
    O2: {
      widget: 'wtext',
      col: 3,
      label: 'O2',

      data: null,
      property: { attrs: { suffix: 'LPM', type: 'number' } }
    },
    bvm: {
      widget: 'wradio',
      col: 4,
      label: ' ',
      //   label: 'BVM',
      data: [
        {
          text: 'BVM',
          value: null,
          field: 'auto'
        }
      ]
    },
    EET: {
      widget: 'wradio',
      label: ' ',
      col: 4,
      data: [
        {
          text: 'EET',
          value: null,
          field: 'auto'
        }
      ]
    },
    pipaoro: {
      widget: 'wradio',
      col: 5,
      label: ' ',
      // label: 'Pipa Oro / Nasopharingeal',
      data: [
        {
          text: 'Pipa Oro / Nasopharingeal',
          value: null,
          field: 'auto'
        }
      ]
    },
    traceheostomy: {
      widget: 'wradio',
      label: ' ',
      col: 4,
      data: [
        {
          text: 'Traceheostomy',
          value: null,
          field: 'auto'
        }
      ]
    },
    cPR: {
      widget: 'wradio',
      label: ' ',
      col: 3,
      data: [
        {
          text: 'CPR',
          value: null,
          field: 'auto'
        }
      ]
    },
    infus: {
      widget: 'wradio',
      label: ' ',
      col: 3,
      data: [
        {
          text: 'Infus',
          value: null,
          field: 'auto'
        }
      ]
    },
    nGT: {
      widget: 'wradio',
      col: 3,
      label: ' ',
      data: [
        {
          text: 'NGT',
          value: null,
          field: 'auto'
        }
      ]
    },
    kateter: {
      widget: 'wradio',
      label: ' ',

      col: 3,
      data: [
        {
          text: 'Kateter',
          value: null,
          field: 'auto'
        }
      ]
    },
    Bidai: {
      widget: 'wradio',
      label: ' ',

      col: 3,
      data: [
        {
          text: 'Kateter',
          value: null,
          field: 'auto'
        }
      ]
    },
    jahit_luka: {
      widget: 'wradio',
      label: ' ',
      col: 3,
      data: [
        {
          text: 'Jahit Luka',
          value: null,
          field: 'auto'
        }
      ]
    },
    data_penujang: {
      widget: 'wradio',
      col: 3,
      label: ' ',

      data: [
        {
          text: 'Data Penunjang',
          value: null,
          field: 'auto'
        }
      ]
    },
    indikasi_dirujuk: {
      widget: 'wradio',
      label: ' ',
      col: 6,
      data: [
        {
          text: 'Alasan / Indikasi Dirujuk',
          value: null,
          field: 'auto'
        }
      ]
    },
    obat_dari_rujukan: {
      widget: 'wradio',
      label: ' ',
      col: 4,
      data: [
        {
          text: 'Obat dari rujukan',
          value: null,
          field: 'auto'
        }
      ]
    },
    riwayat_pengobatan: {
      widget: 'wtext',
      label: 'Riwayat Pengobatan',
      col: 8,
      data: null
    },
    riwayat_alergi: {
      widget: 'wcheckbox',
      label: 'Riwayat Alergi',
      data: [
        {
          text: 'tidak ada alergi',
          value: 'tidak ada alergi'
        },
        {
          text: 'alergi obat',
          value: null,
          field: 'auto',
          float: false
        },
        {
          text: 'alergi makanan',
          value: null,
          field: 'auto',
          float: false
        },
        {
          text: 'alergi lainnya',
          value: null,
          field: 'auto',
          float: false
        },
        {
          text: 'tanda alergi dipasang (warna merah)',
          value: 'tanda alergi dipasang (warna merah)'
        },
        {
          text: 'tidak diketahui',
          value: 'tidak diketahui'
        }
      ]
    }
  },
  jenis_nyeri: {
    penilaian_nyeri: {
      widget: 'wtext',
      label: 'Penilaian Nyeri',
      col: 12,
      data: null
    },
    provokatif: {
      col: 12,
      label: 'Provokatif',

      widget: 'wradio',
      data: [
        {
          text: 'Ruda Paksa',
          value: 'Ruda Paksa'
        },
        {
          text: 'Benturan',
          value: 'Benturan'
        },
        {
          text: 'Sayatan',
          value: 'Sayatan'
        },
        {
          text: 'Dll',
          value: 'Dll'
        },
        {
          text: 'Berkurang Dengan',
          value: 'Berkurang Dengan'
        },
        {
          text: 'Bertambah Dengan',
          value: 'Bertambah Dengan'
        }
      ]
    },
    quality: {
      widget: 'wradio',
      label: 'Quality',

      col: 9,
      data: [
        {
          text: 'tertusuk',
          value: 'tertusuk',
          float: true
        },
        {
          text: 'tertekan tertindih',
          value: 'tertekan tertindih',
          float: true
        },
        {
          text: 'diiris iris',
          value: 'diiris iris',
          float: true
        },
        {
          text: 'dll',
          value: 'dll',
          float: true
        }
      ]
    },
    menjalar: {
      widget: 'wradio',
      label: 'Menjalar',

      col: 3,
      data: [
        {
          text: 'Tidak',
          value: 'Tidak'
        },
        {
          text: 'Ya',
          value: '',
          field: 'auto'
        }
      ]
    },
    region: {
      widget: 'wtext',
      label: 'Region',

      data: null,
      col: 6
    },

    scale: {
      widget: 'wtext',
      label: 'Scale',
      data: null,
      col: 6
    },
    time: {
      widget: 'wradio',
      label: 'Time',

      col: 12,
      data: [
        {
          text: 'jarang',
          value: 'jarang'
        },
        {
          text: 'hilang timbul',
          value: 'hilang timbul'
        },
        {
          text: 'terus menerus',
          value: 'terus menerus'
        }
      ]
    }
  },
  skala_flacc: {
    widget: 'wtext',
    property: { attrs: { type: 'number' } },
    data: [
      {
        text: 'wajah',
        value: 0,
        dataCols: [
          'tersenyum/tidak ada ekspresi khusus',
          'terkadang menangis/menarik diri',
          'sering menggetarkan dagu dan mengatupkan'
        ]
      },
      {
        text: 'kaki',
        value: 0,
        dataCols: [
          'gerakan normal/relaksasi',
          'tidak tenang/tegang',
          'kaki menendang/menarik'
        ]
      },
      {
        text: 'aktifitas',
        value: 0,
        dataCols: [
          'tidur, posisi normal, mudah bergerak',
          'gerakan menggeliat, berguling, kaku',
          'melengkungkan punggung/kaku/menghentak'
        ]
      },
      {
        text: 'menangis',
        value: 0,
        dataCols: [
          'tidak menangis (bangun/tidur)',
          'menggerang, merengek',
          'menangis terus menerus, terisak, menjerit'
        ]
      },
      {
        text: 'bersuara',
        value: 0,
        dataCols: [
          'bersuara normal, tenang',
          'tenang bila dipeluk, digendong/diajak bicara',
          'sulit untuk ditenangkan'
        ]
      },
      {
        text: 'total skor',
        value: 0
      }
    ]
  },
  resiko_jatuh: {
    widget: 'wtext',
    property: { attrs: { type: 'number' } },
    data: [
      {
        text: 'a',

        value: null,
        dataCols: [
          'cara berjalan pasien (salah) atau lebih 1. Tidak Seimbang/sempoyongan/limbung 2. Jalan Dengan Menggunakan Alat Bantu/Bantuan Orang Lain'
        ]
      },
      {
        text: 'b',
        value: null,
        dataCols: [
          'menopang saat akan duduk : tampak memegang pinggiran kursi atau meja/benda lain sebagai penopang saat akan duduk'
        ]
      }
    ]
  },
  pemeriksaan_gizi: {
    berat_bedan: {
      widget: 'wtext',
      col: 4,
      data: null,
      label: 'Berat Badan',
      property: { attrs: { suffix: 'Kg', type: 'number' } }
    },
    tinggi_badan: {
      widget: 'wtext',
      col: 4,
      label: 'Tinggi Badan',

      data: null,
      property: { attrs: { suffix: 'Cm', type: 'number' } }
    },
    imt: {
      widget: 'wtext',
      col: 4,
      data: null,
      label: 'IMT'
    },
    status_berat_badan: {
      col: 12,
      widget: 'wradio',
      data: [
        {
          text: 'BB Kurang',
          value: 'BB Kurang'
        },
        {
          text: 'BB Normal',
          value: 'BB Normal'
        },
        {
          text: 'BB Lebih, (Selanjutnya Akan Dilakukan Skrining Gizi Di Ruang Inap',
          value:
            'BB Lebih, (Selanjutnya Akan Dilakukan Skrining Gizi Di Ruang Inap'
        }
      ]
    },
    status_psikologis: {
      col: 12,
      widget: 'wradio',
      data: [
        {
          text: 'Tidak Terganggu',
          value: 'Tidak Terganggu'
        },
        {
          text: 'Ada Gangguan, Tidak Membahayakan',
          value: 'Ada Gangguan, Tidak Membahayakan'
        },
        {
          text: 'Ada Gangguan, Membahayakan Diri Sendiri/Orang Lain',
          value: 'Ada Gangguan, Membahayakan Diri Sendiri/Orang Lain'
        }
      ]
    },
    status_fungsional: {
      col: 12,
      widget: 'wradio',
      data: [
        {
          text: 'Mandiri',
          value: 'Mandiri'
        },
        {
          text: 'Ada Kelainan Perilaku, Sebutkan',
          value: null,
          field: 'auto'
        },
        {
          text: 'Ketergantungan Total',
          value: 'Ketergantungan Total'
        }
      ]
    },
    status_mental: {
      col: 12,
      widget: 'wradio',
      data: [
        {
          text: 'Sada dan Orientasi Penuh',
          value: 'Sada dan Orientasi Penuh'
        },
        {
          text: 'Ada Kelainan Perilaku, Sebutkan',
          value: null,
          field: 'auto'
        },
        {
          text: 'Ada Prilaku Kekerasaan yang dialami pasie sebelumnya, sebutkan',
          value: null,
          field: 'auto'
        }
      ]
    },
    status_sosial: {
      label: 'Status Sosial (Hubungan pasien dengan keluarga)',
      col: 12,
      widget: 'wradio',
      data: [
        {
          text: 'Baik',
          value: 'Baik'
        },
        {
          text: 'Kurang Baik',
          value: 'Kurang Baik'
        }
      ]
    }
  },
  masalah_keperawatan: {
    masalah_keperawatan1: {
      widget: 'wcheckbox',
      label: 'Masalah Keperawatan',
      col: 6,
      data: [
        {
          text: 'gangguan perfusi jaringan kardiovaskular',
          value: 'gangguan perfusi jaringan kardiovaskular'
        },
        {
          text: 'Gangguan pola nafas tidak efektif',
          value: 'Gangguan pola nafas tidak efektif'
        },
        {
          text: 'gangguan keseimbangan cairan dan elektrolit',
          value: 'gangguan keseimbangan cairan dan elektrolit'
        },
        {
          text: 'resiko syok hipovolemik',
          value: 'resiko syok hipovolemik'
        },
        {
          text: 'gangguan pemenuhan kebutuhan nutrisi',
          value: 'gangguan pemenuhan kebutuhan nutrisi'
        },
        {
          text: 'nyeri',
          value: 'nyeri'
        },
        {
          text: 'peningkatan suhu tubuh',
          value: 'peningkatan suhu tubuh'
        },
        {
          text: 'gangguan integritas kulit',
          value: 'gangguan integritas kulit'
        },
        {
          text: 'gangguan pola eliminasi bab',
          value: 'gangguan pola eliminasi bab'
        }
      ]
    },
    masalah_keperawatan2: {
      widget: 'wcheckbox',
      label: 'Masalah Keperawatan',
      col: 6,
      data: [
        {
          text: 'gangguan pola eliminasi bak',
          value: 'gangguan pola eliminasi bak'
        },
        {
          text: 'resiko infeksi',
          value: 'resiko infeksi'
        },
        {
          text: 'resiko cedera',
          value: 'resiko cedera'
        },
        {
          text: 'gangguan mobilitas fisik',
          value: 'gangguan mobilitas fisik'
        },
        {
          text: 'intoleransi aktifitas',
          value: 'intoleransi aktifitas'
        },
        {
          text: 'gangguan komunikasi verbal nonverbal',
          value: 'gangguan komunikasi verbal nonverbal'
        },
        {
          text: 'resiko pendarahan',
          value: 'resiko pendarahan'
        },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ]
    }
  },
  rencana_keperawatan: {
    rencana_keperawatan1: {
      widget: 'wcheckbox',
      label: 'Rencana Keperawatan',
      col: 6,
      property: { grouping: true, numbering: true, startNum: 1 },
      data: [
        {
          text: 'TD',
          value: 'TD',
          group: 'observasi',
          float: true
        },
        {
          text: 'nadi',
          value: 'nadi',
          group: 'observasi',
          float: true
        },
        {
          text: 'suhu',
          value: 'suhu',
          group: 'observasi',
          float: true
        },
        {
          text: 'turgor',
          value: 'turgor',
          group: 'observasi',
          float: true
        },
        {
          text: 'balance cairan',
          value: 'balance cairan',
          group: 'observasi',
          float: true
        },
        {
          text: 'warna kulit',
          value: 'warna kulit',
          group: 'observasi',
          float: true
        },
        {
          text: 'perfusi jaringan',
          value: 'perfusi jaringan',
          group: 'observasi',
          float: true
        },
        {
          text: 'kedaran',
          value: 'kedaran',
          group: 'observasi',
          float: true
        },
        {
          text: 'gcs',
          value: 'gcs',
          group: 'observasi',
          float: true
        },
        {
          text: 'produksi sputum',
          value: 'produksi sputum',
          group: 'observasi'
        },
        {
          text: 'lakukan auskultasi suara nafas',
          value: 'lakukan auskultasi suara nafas'
        },
        {
          text: 'batasi aktifitas fisik',
          value: 'batasi aktifitas fisik'
        },
        {
          text: 'berikan informasi dukungan untuk mengurangi kecemasan',
          value: 'berikan informasi dukungan untuk mengurangi kecemasan'
        },
        {
          text: 'ajarkan cara batuk efektif',
          value: 'ajarkan cara batuk efektif'
        },
        {
          text: 'cegah pasien melukai diri sendiri dan orang lain',
          value: 'cegah pasien melukai diri sendiri dan orang lain'
        },
        {
          text: 'pemberian terapi',
          value: 'pemberian terapi',
          group: 'kolaborasi dengan tim medis Dalam',
          float: true
        },
        {
          text: 'pemeriksaan penunjang lab ro',
          value: 'pemeriksaan penunjang lab ro',
          group: 'kolaborasi dengan tim medis Dalam'
        }
      ]
    },
    rencana_keperawatan2: {
      widget: 'wcheckbox',
      label: 'Rencana Keperawatan',
      col: 6,
      property: { grouping: true, numbering: true, startNum: 8 },
      data: [
        {
          text: 'kateter',
          value: 'kateter',
          group: 'lakukan pemasangan',
          float: true
        },
        {
          text: 'gibs',
          value: 'gibs',
          group: 'lakukan pemasangan',
          float: true
        },
        {
          text: 'ekg',
          value: 'ekg',
          group: 'lakukan pemasangan',
          float: true
        },
        {
          text: 'iv line',
          value: 'iv line',
          group: 'lakukan pemasangan'
        },
        {
          text: 'kaji tingkat nyeri',
          value: 'kaji tingkat nyeri'
        },
        {
          text: 'lakukan perawatan luka',
          value: 'lakukan perawatan luka'
        },
        {
          text: 'longgarkan pakaian atau beri selimut',
          value: 'longgarkan pakaian atau beri selimut'
        },
        {
          text: 'lakukan nebulizer',
          value: 'lakukan nebulizer'
        },
        {
          text: 'lakukan fisioterapi nafas',
          value: 'lakukan fisioterapi nafas'
        },
        {
          text: 'berikan health education kepada pasien dan keluarga',
          value: 'berikan health education kepada pasien dan keluarga'
        },
        {
          text: 'lain-lain',
          value: null,
          field: 'auto'
        }
      ]
    }
  },
  kriteria_discharge_planning: {
    umur_diatas_65_tahun: {
      widget: 'wradio',
      label: 'Umur > 65 Tahun',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    keterbatasan_mobilitas: {
      widget: 'wradio',
      label: 'Keterbatasan Mobilitas',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    perawatan_atau_pengobatan_lanjutan: {
      widget: 'wradio',
      label: 'Perawatan Atau Pengobatan Lanjutan',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    bantuan_untuk_melakukan_aktifitas_sehari_sehari: {
      widget: 'wradio',
      label: 'Bantuan Untuk Melakukan Aktifitas Sehari Sehari',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    }
  },
  kriteria_perencanaan_pulang: {
    perawatan_diri: {
      widget: 'wcheckbox',
      label: ' ',
      col: 12,
      data: [
        {
          text: 'Perawatan Diri (mandi, BAB, BAK)',
          value: 'Perawatan Diri (mandi, BAB, BAK)',
          float: true
        },
        {
          text: 'Latihan Fisik Lanjutan',
          value: 'Latihan Fisik Lanjutan',
          float: true
        },
        {
          text: 'Bantuan Medis/Perawatan di rumah (home care)',
          value: 'Bantuan Medis/Perawatan di rumah (home care)',
          float: true
        },
        {
          text: 'Pemantaun Pemberian Obat',
          value: 'Pemantaun Pemberian Obat',
          float: true
        },
        {
          text: 'Perawatan Luka',
          value: 'Perawatan Luka',
          float: true
        },
        {
          text: 'Pendampingan Tenaga Khusus Dirumah',
          value: 'Pendampingan Tenaga Khusus Dirumah',
          float: true
        },
        {
          text: 'Bantuan Untuk Melakukan Aktifitas Fisik (Kursi Roda, Alat Bantu Jalan)',
          value:
            'Bantuan Untuk Melakukan Aktifitas Fisik (Kursi Roda, Alat Bantu Jalan)',
          float: true
        }
      ]
    }

  },

  isian_dokter: {
    subjektif: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Subjektif (Anamnesa)'
    },

    objektif: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Objektif (Pemeriksaan Fisik)'
    },

    diagnosa_utama: {
      label: 'Diagnosa Utama',
      widget: 'wtextarea',
      col: 5
    },
    kode_icd_diagnosa_utama: {
      label: 'Kode ICD Utama',
      widget: 'wautocomplate',
      data: [],
      col: 7
    },
    diagnosa_sekunder: {
      label: 'Diagnosa Sekunder',
      widget: 'wtextarea',

      col: 5
    },
    kode_icd_diagnosa_sekunder: {
      label: 'Kode ICD Sekunder',
      widget: 'wautocomplate',
      data: [],
      col: 7
    },

    asessment: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Asessement (Diagnosa Kerja)'
    },
    pemeriksaan_penunjang: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label:
        'Pemeriksaan Penunjang : (EKG/Laboratoriumm/Rontgen/CT-Scan/Lainnya)'
    },

    tindakan_prosedur: {
      label: 'Tindakan',
      widget: 'wtextarea',
      col: 5
    },
    tindakan: {
      label: 'Tindakan Dokter',
      widget: 'wautocomplatetindakan',
      data: [],
      col: 7
    },
    rencana: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Rencana Penatalaksanaan'
    },
    noted: {
      label: 'Noted',
      widget: 'wtextarea',
      col: 12
    },
    planning: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Terapy Non Racikan'
    },
    planning_racikan: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Terapy Racikan'
    }

  },
  kebutuhan_edukasi: {
    hambatan: {
      label: 'Terdapat hambatan dalam pembelajaran',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    sebutkan_hambatan: {
      label:
        'Jika Iya, Sebuthkan Hambatannya (bisa dilingkari lebih dari satu)',
      widget: 'wradio',
      col: 12,
      data: [
        {
          text: 'Pendengaran',
          value: 'Pendengaran'
        },
        {
          text: 'Penglihatan',
          value: 'Penglihatan'
        },
        {
          text: 'Kognitif',
          value: 'Kognitif'
        },
        {
          text: 'Fisik',
          value: 'Fisik'
        },
        {
          text: 'Budaya',
          value: 'Budaya'
        },
        {
          text: 'Agama',
          value: 'Agama'
        },
        {
          text: 'Emosi',
          value: 'Emosi'
        },
        {
          text: 'Bahasa',
          value: 'Bahasa'
        },
        {
          text: 'Lainnya',
          value: null,
          field: 'auto'
        }
      ]
    },
    dibutuhkan_penerjemah: {
      label: 'Dibutuhkan Penerjemah',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Tidak',
          value: 'Tidak'
        },
        {
          text: 'Ya',
          value: '',
          field: 'auto'
        }
      ]
    },
    kebutuhan_pembelajaran: {
      label: 'Kebutuhan Pembelajaran',
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Diagnosa & Manajemen',
          value: 'Diagnosa & Manajemen',
          float: true
        },
        {
          text: 'Obat-Obatan',
          value: 'Obat-Obatan',
          float: true
        },
        {
          text: 'Perawatan Luka',
          value: 'Perawatan Luka'
        },
        {
          text: 'Rehabilitasi',
          value: 'Rehabilitasi'
        },
        {
          text: 'Manajemen Nyeri',
          value: 'Manajemen Nyeri'
        },
        {
          text: 'Diet dan Nutrisi',
          value: 'Diet dan Nutrisi'
        },
        {
          text: 'Lain-Lainnya',
          value: '',
          field: 'auto'
        }
      ]
    },
    jam: {
      widget: 'wtime',
      data: null,
      label: 'Jam',
      col: 6,
      property: { attrs: { suffix: 'WIB' } }
    },
    status_medik: {
      widget: 'wcheckbox',
      col: 6,
      data: [
        {
          text: 'Gawat Darurat',
          value: 'Gawat Darurat'
        },
        {
          text: 'Darurat Tidak Gawat',
          value: 'Darurat Tidak Gawat'
        },
        {
          text: 'Gawat Tidak Darurat',
          value: 'Gawat Tidak Darurat'
        },
        {
          text: 'Tidak, Gawat Tidak Darurat',
          value: 'Tidak, Gawat Tidak Darurat'
        }
      ]
    },
    rencana_tindak_lanjut: {
      label: 'Rencana Tindak Lanjut',
      widget: 'wradio',
      col: 12,
      data: [
        {
          text: 'Pulang Izin Dokter',
          value: null,
          field: 'auto'
        },
        {
          text: 'Rawat Inap Ke',
          value: null,
          field: 'auto'
        },
        {
          text: 'Pulang Atas Permintaan Sendiri',
          value: null,
          field: 'auto'
        },
        {
          text: 'D.O.A',
          value: null,
          field: 'auto'
        },
        {
          text: 'Rujuk ke',
          value: null,
          field: 'auto'
        },
        {
          text: 'Meninggal',
          value: null,
          field: 'auto'
        },
        {
          text: 'Lain-Lain',
          value: null,
          field: 'auto'
        }
      ]
    }
  }
}
