<template>
  <v-container fluid class="mb-5 mt-1">
    <v-row>
      <v-col cols="12" lg="8" sm="8" md="8" class="ma-0">
        <v-card color="teal darken-1" elevation-3 dark>
          <v-card-title>Demografi Pasien</v-card-title>

          <v-card-text>
            <v-row class="mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-2 pt-1 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.demografi[i]"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div class="mt-2" v-if="data.demografi.notes">
          <v-alert shaped prominent type="error">
            {{ data.demografi.notes }}
          </v-alert>
        </div>
        <div class="mt-2" v-if="showmessage">
          <v-alert shaped prominent type="error">
            {{ msgerror }}
          </v-alert>
        </div>
        <div class="m-1">
          <div v-if="cekRole(user.sub_unit_id)">
            <!-- kunjungan -->
            <v-row class="mt-2">
              <v-col
                v-for="(v, i) in master.kunjungan"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.kunjungan[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.kunjungan[i]"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <!-- kasus -->
            <v-divider class="my-6" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.jenis_kasus"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.jenis_kasus[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.jenis_kasus[i]"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <!-- TRIAGE -->
            <v-divider class="my-6" />
            <h3 class="mb-5">Triage</h3>
            <v-radio-group v-model="data.triage_table.skala" mandatory>
              <v-simple-table dense class="elevation-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="font-size: 16px" class="text-left">Check</th>
                      <th style="font-size: 16px" class="text-left">
                        SKALA TRIASE
                      </th>
                      <th style="font-size: 16px" class="text-left">
                        KETERANGAN
                      </th>
                      <th style="font-size: 16px" class="text-left">
                        RESPON TIME
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(v, i) in master.triage_table.data"
                      :key="i"
                      v-bind:style="{ 'background-color': v.color }"
                    >
                      <td class="text-center">
                        <div style="text-align: center">
                          <v-radio
                            outlined
                            :value="v.value"
                            :id="'radio-triage-' + i"
                            class="py-2 text-center"
                            hide-details="auto"
                          />
                        </div>
                      </td>
                      <td
                        class="text-capitalize"
                        style="font-size: 16px"
                        v-for="(dcol, j) in v.dataCols"
                        :key="j"
                      >
                        {{ dcol }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-radio-group>
            <!-- tanda vital -->
            <v-divider class="my-6" />
            <v-row>
              <v-col
                v-for="(v, i) in master.tanda_vital"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                :key="i"
              >
                <!-- {{data.tanda_vital[i]}} -->
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.tanda_vital[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.tanda_vital[i]"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="my-6" />
            <v-row>
              <v-col
                v-for="(v, i) in master.penyakit_dahulu"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-1 mb-1 pt-2 pb-1 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.penyakit_dahulu[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.penyakit_dahulu[i]"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <!-- riwayat penyakit terdahulu -->
            <v-divider class="my-6" />
            <v-col cols="12" md="12" class="py-0">
              <div class="text-center font-weight-bold mb-4">
                SEKALA NYERI DEWASA
              </div>
              <sekala-nyeri
                :sync-value="(v) => (data.skala_nyeri = v)"
                :value="data.skala_nyeri"
              />
            </v-col>
            <a href="#test1" id="test1"></a>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              Asesmen Gawat Darurat
            </div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.inputan_petugas_pendaftaran"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-1 mb-1 pt-2 pb-1 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.inputan_petugas_pendaftaran[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.inputan_petugas_pendaftaran[i]"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">Keadaan Pra-Hospital</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.inputan_perawat"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-1 mb-1 pt-2 pb-1 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.inputan_perawat[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.inputan_perawat[i]"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="my-1" />
            <h5 style="text-align: center">SKRINING NYERI</h5>
            <v-divider class="my-1" />

            <v-col cols="12" md="12" lg="12" sm="12">
              <div class="text-center font-weight-bold mb-4">
                SEKALA NYERI DEWASA
              </div>
              <sekala-nyeri
                :sync-value="(v) => (data.skala_nyeri2 = v)"
                :value="data.skala_nyeri2"
              />
            </v-col>
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.jenis_nyeri"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-1 mb-1 pt-2 pb-1 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.jenis_nyeri[i] = e)"
                  vclass="c-text-field"
                  :value="data.jenis_nyeri[i]"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mb-3 mt-3" />

            <h5 class="mb-5" style="text-align: center">
              Skala FLACC Untuk Anak Di Bawah 3 Tahun
            </h5>
            <v-divider class="mb-3 mt-3" />
            <div class="mb-4">
              <strong>0</strong> = Nyaman; <strong>1-3</strong> = Kurang nyaman;
              <strong>4-6</strong> = Nyeri sedang; <strong>7-10</strong> = Nyeri
              berat
            </div>
            <table
              class="elevation-1 table table-bordered table-sm"
              width="100%"
            >
              <thead>
                <tr>
                  <th class="text-center" width="15%">Nilai</th>
                  <th class="text-left">Pengkajian</th>
                  <th class="text-center">0</th>
                  <th class="text-center">1</th>
                  <th class="text-center">2</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, i) in master.skala_flacc.data" :key="i">
                  <td class="text-center">
                    <v-text-field
                      type="number"
                      dense
                      class="py-2 ma-2 text-center"
                      :rules="[rules.tidakMinus, rules.tidakLebihDari]"
                      :id="'input-skala-flacc-' + i"
                      hide-details="auto"
                      @focus="$event.target.select()"
                      @input="data.skala_flacc[d.text] = $event"
                      v-bind="
                        typeof d.dataCols === 'undefined'
                          ? { readonly: true, value: skorSkalaFlacc }
                          : {
                              value: data.skala_flacc[d.text],
                            }
                      "
                    />
                  </td>
                  <td
                    class="text-capitalize"
                    v-bind="
                      typeof d.dataCols === 'undefined'
                        ? {
                            colspan: 4,
                            class: 'text-center font-weight-bold',
                          }
                        : {}
                    "
                  >
                    {{ d.text }}
                  </td>
                  <td
                    class="text-capitalize"
                    v-for="(dcol, j) in d.dataCols"
                    :key="j"
                  >
                    {{ dcol }}
                  </td>
                </tr>
              </tbody>
            </table>
            <v-divider class="my-6" />
            <div class="font-weight-bold">Resiko Jatuh (UP and GO)</div>
            <div class="mb-4" />
            <v-row>
              <v-col cols="12" md="8">
                <v-simple-table class="elevation-1">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="font-size: 16px" class="text-center"></th>
                        <th style="font-size: 16px" class="text-left">
                          Pengkajian
                        </th>
                        <th style="font-size: 16px" class="text-center">Ya</th>
                        <th style="font-size: 16px" class="text-center">
                          Tidak
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>a.</td>
                        <td
                          class="text-capitalize py-3"
                          style="font-size: 16px"
                        >
                          Cara berjalan pasien (salah satu atau Iebih): <br />1.
                          Tidak seimbang/sempoyongan/limbung <br />2. Jalan
                          dengan menggunakan alat bantu/bantuan orang lain
                        </td>
                        <td colspan="2">
                          <v-radio-group
                            v-model="data.resiko_jatuh.case_a"
                            mandatory
                          >
                            <v-row>
                              <v-col>
                                <v-radio value="ya" />
                              </v-col>
                              <v-col>
                                <v-radio value="tidak" />
                              </v-col>
                            </v-row>
                          </v-radio-group>
                        </td>
                      </tr>
                      <tr>
                        <td>b.</td>
                        <td
                          class="text-capitalize py-3"
                          style="font-size: 16px"
                        >
                          Menopang saat akan duduk : tampak memegang pinggiran
                          kursi atau meja/ benda lain sebagai penopang saat akan
                          duduk
                        </td>
                        <td colspan="2">
                          <v-radio-group
                            v-model="data.resiko_jatuh.case_b"
                            mandatory
                          >
                            <v-row>
                              <v-col>
                                <v-radio value="ya" />
                              </v-col>
                              <v-col>
                                <v-radio value="tidak" />
                              </v-col>
                            </v-row>
                          </v-radio-group>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="12" md="4">
                <strong>Keterangan</strong>
                <ul>
                  <li>
                    a dan b tidak ditemukan: <strong>Tidak beresiko</strong>
                  </li>
                  <li>
                    Ditemukan salah satu a atau b:
                    <strong>Resiko rendah</strong>
                  </li>
                  <li>Ditemukan a dan b: <strong>Resiko tinggi</strong></li>
                </ul>
              </v-col>
            </v-row>
            <v-divider class="my-6" />
            <v-row>
              <v-col
                v-for="(v, i) in master.pemeriksaan_gizi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.pemeriksaan_gizi[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.pemeriksaan_gizi[i]"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>

            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">Masalah Keperawatan</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.masalah_keperawatan"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                :key="i"
                cols="12"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.masalah_keperawatan = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :value="data.masalah_keperawatan"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">Rencana Keperawatan</div>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.rencana_keperawatan"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                :key="i"
                cols="12"
              >
                <!-- {{ data.rencana_keperawatan }} -->
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.rencana_keperawatan = e)"
                  :value="data.rencana_keperawatan"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <div class="font-weight-bold text-center">
              Perencanaan Pulang / Discharge Planning
            </div>
            <p class="text-center">
              <strong><i>Kriteria Discharge Planning</i></strong>
            </p>

            <v-row class="mt-1">
              <v-col
                v-for="(v, i) in master.kriteria_discharge_planning"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.kriteria_discharge_planning[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :value="data.kriteria_discharge_planning[i]"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <p class="mt-2">
              <strong>
                Bila salah satu jawaban "Ya" dari kriteria perencanaan pulang
                diatas, maka akan dilanjutkan dengan perencanaan pulang sebagai
                berikut</strong
              >
            </p>
            <v-row>
              <v-col
                v-for="(v, i) in master.kriteria_perencanaan_pulang"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                :class="`mt-0 mb-0 pt-0 pb-0 ${i}`"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.kriteria_perencanaan_pulang[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :property="v.property"
                  :value="data.kriteria_perencanaan_pulang[i]"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
          </div>

          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center mt-2">
            <h4>Isian Dokter</h4>
          </div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.isian_dokter"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
            >
              <!-- {{ data.isian_dokter[i] }} -->
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.isian_dokter[i] = e)"
                :value="data.isian_dokter[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <div class="font-weight-bold text-center">Kebutuhan Edukasi</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.kebutuhan_edukasi"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.kebutuhan_edukasi[i] = e)"
                vclass="c-text-field"
                :data="v.data"
                :value="data.kebutuhan_edukasi[i]"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12" lg="4" sm="4" md="4">
        <ListHistoryAsesmen />
      </v-col>
    </v-row>
    <ICARE
      ref="icare"
      :show-dialog="dialogICARE"
      :negative-button="dialogActionNBtnLKirimICARE"
      :disabled-negative-btn="dialogDisableNBtnKirimICARE"
      :itemUse="data"
    />
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn
          @click="postSave"
          medium
          :disabled="disablebuttonSimpan"
          :color="color"
          class="btn-block btn-simpan"
        >
          Simpan Form
        </v-btn>
        <v-btn
          @click="selesaikanLayanan"
          medium
          dark
          :disabled="disablebuttonSimpan"
          :color="colorSelesaikan"
          class="btn-block btn-simpan"
        >
          Selesaikan Isian
        </v-btn>
        <v-btn class="btn-block" @click="icare" color="green" dark
          >ICARE
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
import SmartWidget from '@/components/SmartWidget.vue'
import SekalaNyeri from '../../components/SekalaNyeri.vue'
import Master from '../../data/asesmen-triage-inap'
import ICARE from '@/components/ICARE'

// eslint-disable-next-line no-unused-vars
import ListHistoryAsesmen from './ListHistoryAsesmen.vue'
// import { mapMutations } from 'vuex'

// import { isEmpty, jsonPrettier } from '@/plugins/supports'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    SmartWidget,
    SekalaNyeri,
    // eslint-disable-next-line vue/no-unused-components
    ICARE,
    // eslint-disable-next-line vue/no-unused-components
    ListHistoryAsesmen
  },
  data () {
    return {
      dialogICARE: false,
      dialogActionNBtnLKirimICARE: () => {
        this.dialogICARE = false
      },

      dialogDisableNBtnKirimICARE: false,

      user: JSON.parse(localStorage.getItem('user')),
      item_ke: null,
      tabKanan: null,
      item_ke_diagnosa_utama: null,
      master: Master,
      msgerror: '',
      color: 'primary',
      colorSelesaikan: 'blue darken-1',
      showmessage: false,

      disablebuttonSimpan: false,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: '',
          sub_unit_id: '',
          umur: '',
          handphone: '',
          notes: '',
          kartu_anggota: ''
        },
        kunjungan: {
          status_kunjungan: '',
          cara_datang: '',
          datang_di_IGD_tanggal: '',
          jam: ''
        },

        jenis_kasus: {
          kasus: '',
          trauma_penyebab_utama: ''
        },
        triage_table: {
          pilih: '',
          skala: ''
        },
        tanda_vital: {
          keadaan_umum: '',
          kesadaraan: '',
          GCS: '',
          TD: '',
          E: '',
          M: '',
          V: '',
          Nadi: '',
          RR: '',
          reflek_cahaya: '',
          pupil_mata: '',
          suhu: '',
          saturasi_O2: '',
          anamnesis: '',
          jenis_pilihan: ''
        },
        penyakit_dahulu: {
          riwayat_penyakit_terdahulu: null,
          alergi: null
        },
        skala_nyeri: null,
        skala_nyeri2: null,
        inputan_petugas_pendaftaran: {
          datang_di_IGD_tanggal: '-',
          jam: '-',
          pekerjaan: '-',
          penghasilan: '-',
          agama: '-',
          suku_budaya: '',
          pendidikan: '',
          bahasa: ''
        },
        isian_dokter: {
          subjektif: '',
          diagnosa_utama: '',
          kode_icd_diagnosa_utama: '',
          diagnosa_sekunder: '',
          kode_icd_diagnosa_sekunder: '',
          objektif: '',
          pemeriksaan_penunjang: '',
          asessment: '',
          tindakan_prosedur: '',
          tindakan: '',
          noted: '',
          planning: '',
          planning_racikan: '',
          rencana: ''
        },
        resiko_jatuh: { case_a: null, case_b: null, result: null },
        masalah_keperawatan: '',
        rencana_keperawatan: '',
        kriteria_discharge_planning: {
          keterbatasan_mobilitas: '',
          perawatan_atau_pengobatan_lanjutan: '',
          bantuan_untuk_melakukan_aktifitas_sehari_sehari: '',
          umur_diatas_65_tahun: ''
        },
        kriteria_perencanaan_pulang: {
          perawatan_diri: '',
          latihan_fisik: '',
          bantuan_medis: '',
          pemantauan_obat: '',
          perawatan_luka: '',
          pendampingan_tenaga_khusus: '',
          bantuan_melakukan: ''
        },
        kebutuhan_edukasi: {
          hambatan: '',
          sebutkan_hambatan: '',
          rencana_tindak_lanjut: ''
        },
        pemeriksaan_gizi: {
          berat_bedan: '',
          tinggi_badan: '',
          imt: '',
          status_berat_badan: '',
          status_psikologis: '',
          status_fungsional: '',
          status_mental: '',
          status_sosial: ''
        },
        inputan_perawat: {
          tanggal: '-',
          jam: '-',
          di: '-',
          GCS: '-',
          TD: '-',
          n: '-',
          s: '-',
          rR: '-',
          sPO2: '-',
          O2: '-',
          bvm: '-',
          EET: '-',
          pipaoro: '-',
          traceheostomy: '-',
          cPR: '-',
          infus: '-',
          nGT: '-',
          kateter: '-',
          Bidai: '-',
          jahit_luka: '-',
          data_penujang: '-',
          indikasi_dirujuk: '-',
          obat_dari_rujukan: '-',
          riwayat_pengobatan: '',
          riwayat_alergi: ''
        },
        jenis_nyeri: {
          penilaian_nyeri: '',
          provokatif: '',
          quality: '',
          menjalar: '',
          region: '',
          scale: '',
          time: ''
        },
        skala_flacc: {
          wajah: 0,
          kaki: 0,
          aktifitas: 0,
          menangis: 0,
          bersuara: 0,
          'total skor': 0
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        tidakMinus: (value) => {
          return true
        },
        tidakLebihDari: (value) => {
          // return true
          if (!isNaN(value)) {
            const t = parseInt(value)
            if (t <= 10) {
              return true
            } else {
              return 'Max Nilai 2'
            }
          }
          return 'Harus Angka'
        }
      }
    }
  },
  methods: {
    // ...mapMutations(['set_contents'])
    // jsonPrettier

    cekRole (RESULTS) {
      const myArray = ['64', '65']
      if (!myArray.includes(RESULTS)) {
        return true
      }

      // console.log(RESULTS + ' hasil role')
      // // eslint-disable-next-line eqeqeq
      // return myArray.some(item => item != RESULTS)
    },

    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          if (response.data.con) {
            this.dataPatient = response.data.results
            this.data.demografi = this.dataPatient
            this.data.demografi.nama_pasien =
              response.data.results.sapaan + response.data.results.nama_pasien
            this.data.demografi.jenis_kelamin = {
              value: this.dataPatient.jenis_kelamin,
              text: this.dataPatient.jenis_kelamin
            }
            this.data.demografi.folio_id = this.$route.query.folio_id
            this.data.demografi.reg_id = this.$route.query.registration_id
            this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

            this.patient_name = response.data.results.nama_pasien

            // console.log(response.data.results.tanggal_lahir)
            this.data.demografi.umur = response.data.results.umur
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    umur (tglLahir) {
      var dob = new Date(tglLahir)
      var dobYear = dob.getYear()
      var dobMonth = dob.getMonth()
      var dobDate = dob.getDate()
      var yearAge = ''
      // get the current date from the system
      var now = new Date()
      // extract the year, month, and date from current date
      var currentYear = now.getYear()
      var currentMonth = now.getMonth()
      var currentDate = now.getDate()

      // declare a variable to collect the age in year, month, and days
      var age = {}
      var ageString = ''

      // get years
      yearAge = currentYear - dobYear

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        var monthAge = currentMonth - dobMonth
      } else {
        yearAge--
        // eslint-disable-next-line no-redeclare
        var monthAge = 12 + currentMonth - dobMonth
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate
      } else {
        monthAge--
        // eslint-disable-next-line no-redeclare
        var dateAge = 31 + currentDate - dobDate

        if (monthAge < 0) {
          monthAge = 11
          yearAge--
        }
      }
      // group the age in a single variable
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      } else if (age.years === 0 && age.months === 0 && age.days > 0) {
        ageString = ' ' + age.days + ' Hari'
        // eslint-disable-next-line brace-style
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months === 0 && age.days === 0) {
        ageString = age.years + ' Tahun. Happy Birthday!!'
      } else if (age.years > 0 && age.months > 0 && age.days === 0) {
        ageString = age.years + ' Tahun dan ' + age.months + ' Bulan.'
      } else if (age.years === 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + ' Bulan dan ' + age.days + ' Hari.'
      } else if (age.years > 0 && age.months === 0 && age.days > 0) {
        ageString = age.years + ' Tahun, dan' + age.days + ' Hari.'
      } else if (age.years === 0 && age.months > 0 && age.days === 0) {
        ageString = age.months + ' Bulan.'
        // eslint-disable-next-line brace-style
      }
      // when current date is same as dob(date of birth)
      else {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      }

      // console.log(ageString)
      return ageString
    },
    icare () {
      this.dialogICARE = true

      this.$refs.icare.cariRiwayatIcare()
    },
    postSave () {
      const ARRAYPERAWAT = ['16']
      const ARRAYDOKTER = ['64', '65']

      // console.log(ARRAYPERAWAT.includes(this.user.sub_unit_id))
      // console.log(this.user.sub_unit_id)
      if (ARRAYPERAWAT.includes(this.user.sub_unit_id)) {
        // return true
        if (this.data.kunjungan.datang_di_IGD_tanggal.length === 0) {
          errorMsg('TANGGAL MASUK IGD WAJIB DI ISI')
          return
          // throw new Error('Exiting the function')
        }
        if (this.data.kunjungan.jam.length === 0) {
          errorMsg('JAM MASUK IGD WAJIB DI ISI')
          return

          // throw new Error('Exiting the function')
        }
      }

      if (ARRAYDOKTER.includes(this.user.sub_unit_id)) {
        // return true
        if (this.data.isian_dokter.diagnosa_utama.length === 0) {
          errorMsg('DIAGNOSA WAJIB DIISI')
          return
          // throw new Error('Exiting the function')
        }
      }

      // CEK VALIDASI PERAWAT
      this.disablebuttonSimpan = true
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Menyimpan Resume Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Simpan Resume',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-unused-vars
          const data = {
            no_rm: this.$route.query.no_rm,
            folio_id: this.$route.query.folio_id,
            registration_id: this.$route.query.registration_id,
            sub_unit_id: this.$route.query.sub_unit_id,
            nama: this.user.employee_name,
            employee_id: this.user.employee_id,
            role: this.user.sub_unit_name,
            // eslint-disable-next-line no-undef
            isian_dokter: LZString.compressToBase64(
              JSON.stringify(this.data.isian_dokter)
            ),
            // eslint-disable-next-line no-undef
            isian_perawat: LZString.compressToBase64(JSON.stringify(this.data)),
            // eslint-disable-next-line no-undef
            data: LZString.compressToBase64(JSON.stringify(this.data))
          }

          console.log(data)

          // console.log(compressedString)
          // eslint-disable-next-line no-undef
          $.post(
            baseUrl + 'igd/simpan-asesmen-igd',
            data,
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res

              if (con) {
                this.disablebuttonSimpan = false
                this.color = 'primary'
                successMsg(msg)
                this.getAsesmenTriage(this.$route.query.folio_id)
                this.$root.$emit('SETDATAULANG')
                // setTimeout(() => {
                // }, 100)
              } else {
                this.disablebuttonSimpan = false
                this.color = 'primary'
                errorMsg(msg)
              }
            },
            'JSON'
          )
        } else {
          this.disablebuttonSimpan = false
          this.color = 'primary'
          // resetBtnLoading(btn, html)
          // errorMsg('Nggak Jadi Deh !!!')
        }
      })

      // console.log(JSON.stringify(this.data.isian_dokter) + 'DATA ')
      // console.log(JSON.stringify(this.data.penyakit_dahulu) + 'DATA ')
    },
    appendTechnique (satu, dua) {
      // var a = 'how you are'
      if (satu.indexOf(dua) > -1) {
        // jika sudah ada / contained
        return satu
      }
      if (satu !== '' && satu !== dua) {
        // jika tidak kosong dan tidak inputan yang sama dgn skrng
        // alert('sadads')
        return dua + '\n' + satu
        // append
      } else {
        return dua
      }
    },

    selesaikanLayanan () {
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Menyelesaikan Pengisian Asesmen Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Yakin',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrl + 'igd/selesaikan-layanan',
            {
              folio_id: this.$route.query.folio_id,
              employee_id: this.user.employee_id
            },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                // this.disablebuttonSimpan = false
                // console.log(dataDec)
                successMsg(msg)
                this.$router.push({
                  name: 'FORMIGD',
                  // params: { no_rm: v.medrec_no, folio_id: v.folio_id, registration_id: v.registration_id }
                  query: {
                    no_rm: '',
                    folio_id: '',
                    registration_id: '',
                    sub_unit_id: '',
                    type: 'igd'
                  }
                })
              } else {
                errorMsg(msg)
                // this.disablebuttonSimpan = true
                // this.msgerror = msg
                // this.showmessage = true
              }
            },
            'JSON'
          )
        } else {
          errorMsg('OK TIDAK JADI')
        }
      })
    },
    cekForm () {
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        $.get(
          baseUrl + 'igd/cek-form',
          {
            folio_id: this.$route.query.folio_id,
            employee_id: this.user.employee_id
          },
          (res) => {
            // eslint-disable-next-line no-unused-vars
            const { con, msg, results } = res
            if (con) {
              this.disablebuttonSimpan = false
              // console.log(dataDec)
            } else {
              errorMsg(msg)
              this.disablebuttonSimpan = true
              this.msgerror = msg
              this.showmessage = true
            }
          },
          'JSON'
        )
      }, 150)
    },
    getAsesmenTriage (folioId) {
      this.disablebuttonSimpan = true

      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'igd/get-data-asesmen',
        {
          folio_id: folioId,
          employee_id: this.user.employee_id
        },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.disablebuttonSimpan = false

            // successMsg(msg)
            // eslint-disable-next-line no-undef, no-unused-vars
            var data = LZString.decompressFromBase64(results.pemeriksaan)
            // eslint-disable-next-line no-undef, no-unused-vars
            var dataIsianDokter = LZString.decompressFromBase64(
              results.pemeriksaan
            )
            // eslint-disable-next-line no-undef, no-unused-vars
            var dataIsianPerawat = LZString.decompressFromBase64(
              results.pemeriksaan
            )
            var dataDec = JSON.parse(data)
            this.data.kunjungan = dataDec.kunjungan
            this.data.jenis_kasus = dataDec.jenis_kasus
            this.data.triage_table = dataDec.triage_table
            this.data.tanda_vital = dataDec.tanda_vital
            this.data.penyakit_dahulu = dataDec.penyakit_dahulu
            this.data.skala_nyeri = dataDec.skala_nyeri
            this.data.skala_nyeri2 = dataDec.skala_nyeri2
            this.data.inputan_petugas_pendaftaran =
              dataDec.inputan_petugas_pendaftaran
            this.data.inputan_perawat = dataDec.inputan_perawat
            this.data.jenis_nyeri = dataDec.jenis_nyeri
            this.data.resiko_jatuh = dataDec.resiko_jatuh
            this.data.skala_flacc = dataDec.skala_flacc
            this.data.isian_dokter = dataDec.isian_dokter
            this.data.pemeriksaan_gizi = dataDec.pemeriksaan_gizi
            this.data.masalah_keperawatan = dataDec.masalah_keperawatan
            this.data.rencana_keperawatan = dataDec.rencana_keperawatan
            this.data.kriteria_discharge_planning =
              dataDec.kriteria_discharge_planning
            this.data.kriteria_perencanaan_pulang =
              dataDec.kriteria_perencanaan_pulang
            this.data.kebutuhan_edukasi = dataDec.kebutuhan_edukasi
            // console.log(dataDec)
          } else {
            errorMsg(msg)
            this.disablebuttonSimpan = false
            this.msgerror = msg
            this.showmessage = true
          }
        },
        'JSON'
      )
    }
  },
  mounted () {
    this.$root.$on('SETDATAULANG', (item) => {
      if (this.$route.query.no_rm != null) {
        setTimeout(() => {
          this.getAsesmenTriage(this.$route.query.folio_id)
        }, 100)

        setTimeout(() => {
          this.getDataPasien(this.$route.query.no_rm)
        }, 100)
      }
      // setTimeout(() => {
      //   this.postSaveBackground()
      // }, 1000)
    })
  },
  created () {
    if (this.$route.query.no_rm != null) {
      setTimeout(() => {
        this.getAsesmenTriage(this.$route.query.folio_id)
        this.cekForm()
      }, 100)

      setTimeout(() => {
        this.getDataPasien(this.$route.query.no_rm)
      }, 100)
    }
  },
  computed: {
    skorSkalaFlacc () {
      const tmp = this.data.skala_flacc
      let totalSkor = 0
      for (const key in tmp) {
        if (key !== 'total skor') {
          if (!isNaN(tmp[key])) {
            let t = parseInt(tmp[key])
            if (t >= 0) {
              if (t > 2) {
                t = 2
              }
              tmp[key] = t
              // console.log('t = ', t)
              totalSkor += t
            } else {
              tmp[key] = t * -1
              totalSkor += t * -1
              // console.log('tmp[key] = ', tmp[key])
            }
          }
        }
      }
      tmp['total skor'] = totalSkor
      return totalSkor
    },
    combinedData () {
      return {
        keadaan_umum: this.data.tanda_vital.keadaan_umum,
        kesadaraan: this.data.tanda_vital.kesadaraan,
        GCS: this.data.tanda_vital.GCS,
        TD: this.data.tanda_vital.TD,
        Nadi: this.data.tanda_vital.Nadi,
        RR: this.data.tanda_vital.RR,
        reflek_cahaya: this.data.tanda_vital.reflek_cahaya,
        pupil_mata: this.data.tanda_vital.pupil_mata,
        suhu: this.data.tanda_vital.suhu,
        saturasi_O2: this.data.tanda_vital.saturasi_O2,
        anamnesis: this.data.tanda_vital.anamnesis,
        berat_bedan: this.data.pemeriksaan_gizi.berat_bedan,
        tinggi_badan: this.data.pemeriksaan_gizi.tinggi_badan,
        imt: this.data.pemeriksaan_gizi.imt,
        E: this.data.tanda_vital.E,
        M: this.data.tanda_vital.M,
        V: this.data.tanda_vital.V
        // jenis_pilihan: this.data.tanda_vital.jenis_pilihan
      }
    }
    // beratBeda () {
    //   return {
    //     berat_bedan: this.pemeriksaan_gizi.berat_bedan,
    //     tinggi_badan: this.pemeriksaan_gizi.tinggi_badan,
    //     imt: this.pemeriksaan_gizi.imt
    //   }
    // }
  },

  watch: {
    combinedData (newVal, oldVal) {
      // E
      if (newVal.E || newVal.M || newVal.V) {
        console.log(newVal.Nadi)
        var dataEMV = this.data.isian_dokter.objektif
        // console.log(dataRR + 'RR')
        if (typeof dataEMV !== 'undefined') {
          var bracketEMV = dataEMV.match(/\([^)]*\)/g)
          // console.log(bracketEMV + 'style')
          // console.log(bracketEMV)
          var EMV =
            '(P#EMV: ' + newVal.E + ',' + newVal.M + ',' + newVal.V + ')'
          // console.log(RR + ' asdasda')
          this.item_ke = null
          if (bracketEMV !== null) {
            bracketEMV.forEach((item, index) => {
              if (item.includes('(P#EMV: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketEMV[this.item_ke],
                  EMV
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                EMV
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              EMV
            )
          }
        } else {
          var EMVS =
            '(P#EMV: ' + newVal.E + ',' + newVal.M + ',' + newVal.V + ')'

          this.data.isian_dokter.objektif = EMVS
        }
      }

      // RR
      if (newVal.Nadi) {
        console.log(newVal.Nadi)
        var dataNadi = this.data.isian_dokter.objektif
        // console.log(dataRR + 'RR')
        if (typeof dataNadi !== 'undefined') {
          var bracketNadi = dataNadi.match(/\([^)]*\)/g)
          // console.log(bracketNadi + 'style')
          // console.log(bracketNadi)
          var NADI = '(P#Nadi: ' + newVal.Nadi + ')'
          // console.log(RR + ' asdasda')
          this.item_ke = null
          if (bracketNadi !== null) {
            bracketNadi.forEach((item, index) => {
              if (item.includes('(P#Nadi: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketNadi[this.item_ke],
                  NADI
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                NADI
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              NADI
            )
          }
        } else {
          var Nadis = '(P#Nadi: ' + newVal.Nadi + ')'

          this.data.isian_dokter.objektif = Nadis
        }
      }
      if (newVal.RR) {
        var dataRR = this.data.isian_dokter.objektif
        // console.log(dataRR + 'RR')
        if (typeof dataRR !== 'undefined') {
          var bracketsRR = dataRR.match(/\([^)]*\)/g)
          // console.log(bracketsRR + 'style')
          // console.log(bracketsRR)
          var RR = '(P#RR: ' + newVal.RR + ')'
          // console.log(RR + ' asdasda')
          this.item_ke = null
          if (bracketsRR !== null) {
            bracketsRR.forEach((item, index) => {
              if (item.includes('(P#RR: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketsRR[this.item_ke],
                  RR
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                RR
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              RR
            )
          }
        } else {
          var RRss = '(P#RR: ' + newVal.RR + ')'

          this.data.isian_dokter.objektif = RRss
        }
      }

      // KEADAAAN UMUM
      if (newVal.keadaan_umum) {
        var data = this.data.isian_dokter.objektif
        console.log(data + 'KEADDAAN')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\([^)]*\)/g)
          // console.log(brackets + 'style')
          // console.log(brackets)
          var tandavitalumum = '(P#Keadaan Umum: ' + newVal.keadaan_umum + ')'
          // console.log(tandavitalumum + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('(P#Keadaan Umum: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  brackets[this.item_ke],
                  tandavitalumum
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                tandavitalumum
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              tandavitalumum
            )
          }
        } else {
          var tandavitalumumss =
            '(P#Keadaan Umum: ' + newVal.keadaan_umum + ')'

          this.data.isian_dokter.objektif = tandavitalumumss
        }
      }
      // KESADARAN
      if (newVal.kesadaraan) {
        var datakesadaraan = this.data.tanda_vital.objektif
        //   // console.log(data + ' anak')
        if (typeof datakesadaraan !== 'undefined') {
          var bracketskesadaraan = datakesadaraan.match(/\([^)]*\)/g)
          // console.log(bracketskesadaraan + 'style')
          // console.log(brackets)
          var kesadaraan = '(P#Kesadaran: ' + newVal.kesadaraan + ')'
          // console.log(tandavitalumum + ' asdasda')
          this.item_ke = null
          if (bracketskesadaraan !== null) {
            bracketskesadaraan.forEach((item, index) => {
              if (item.includes('(P#Kesadaran: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.tanda_vital.objektif =
                this.data.tanda_vital.objektif.replace(
                  bracketskesadaraan[this.item_ke],
                  kesadaraan
                )
            } else {
              this.data.tanda_vital.objektif = this.appendTechnique(
                this.data.tanda_vital.objektif,
                kesadaraan
              )
            }
          } else {
            this.data.tanda_vital.objektif = this.appendTechnique(
              this.data.tanda_vital.objektif,
              kesadaraan
            )
          }
        } else {
          var kesadaraans = '(P#Kesadaran: ' + newVal.kesadaraan + ')'

          this.data.tanda_vital.objektif = kesadaraans
        }
      }
      // GCS
      if (newVal.GCS) {
        var dataGCS = this.data.isian_dokter.objektif
        //   // console.log(data + ' anak')
        if (typeof dataGCS !== 'undefined') {
          var bracketsGCS = dataGCS.match(/\([^)]*\)/g)
          // console.log(bracketsGCS + 'style')
          // console.log(brackets)
          var GCS = '(P#GCS: ' + newVal.GCS + ')'
          // console.log(tandavitalumum + ' asdasda')
          this.item_ke = null
          if (bracketsGCS !== null) {
            bracketsGCS.forEach((item, index) => {
              if (item.includes('(P#GCS: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketsGCS[this.item_ke],
                  GCS
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                GCS
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              GCS
            )
          }
        } else {
          var GCSs = '(P#GCS: ' + newVal.kesadaraan + ')'

          this.data.isian_dokter.objektif = GCSs
        }
      }
      // TD
      if (newVal.TD) {
        var dataTD = this.data.isian_dokter.objektif
        //   // console.log(data + ' anak')
        if (typeof dataTD !== 'undefined') {
          var bracketsTD = dataTD.match(/\([^)]*\)/g)
          // console.log(bracketsTD + 'style')
          // console.log(brackets)
          var TD = '(P#TD: ' + newVal.TD + ')'
          // console.log(tandavitalumum + ' asdasda')
          this.item_ke = null
          if (bracketsTD !== null) {
            bracketsTD.forEach((item, index) => {
              if (item.includes('(P#TD: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketsTD[this.item_ke],
                  TD
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                TD
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              TD
            )
          }
        } else {
          var TDs = '(P#TD: ' + newVal.kesadaraan + ')'

          this.data.isian_dokter.objektif = TDs
        }
      }
      // REFLEK CAHAYA
      if (newVal.reflek_cahaya) {
        var datareflekCahaya = this.data.isian_dokter.objektif
        //   // console.log(data + ' anak')
        if (typeof datareflekCahaya !== 'undefined') {
          var bracketsReflekCahayar = datareflekCahaya.match(/\([^)]*\)/g)
          // console.log(bracketsReflekCahayar + 'style')
          // console.log(brackets)
          var reflekcahaya = '(P#Reflek Cahaya: ' + newVal.reflek_cahaya + ')'
          // console.log(tandavitalumum + ' asdasda')
          this.item_ke = null
          if (bracketsReflekCahayar !== null) {
            bracketsReflekCahayar.forEach((item, index) => {
              if (item.includes('(P#Reflek Cahaya: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketsReflekCahayar[this.item_ke],
                  reflekcahaya
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                reflekcahaya
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              reflekcahaya
            )
          }
        } else {
          var reflekCahayas = '(P#Reflek Cahaya: ' + newVal.reflek_cahaya + ')'

          this.data.isian_dokter.objektif = reflekCahayas
        }
      }
      // PUPIL MATA
      if (newVal.pupil_mata) {
        var datapupilMata = this.data.isian_dokter.objektif
        //   // console.log(data + ' anak')
        if (typeof datapupilMata !== 'undefined') {
          var bracketsPupilMata = datapupilMata.match(/\([^)]*\)/g)
          // console.log(bracketsPupilMata + 'style')
          // console.log(brackets)
          var pupilMata = '(P#Pupil Mata: ' + newVal.pupil_mata + ')'
          // console.log(tandavitalumum + ' asdasda')
          this.item_ke = null
          if (bracketsPupilMata !== null) {
            bracketsPupilMata.forEach((item, index) => {
              if (item.includes('(P#Pupil Mata: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketsPupilMata[this.item_ke],
                  pupilMata
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                pupilMata
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              pupilMata
            )
          }
        } else {
          var pupilMatas = '(P#Pupil Mata: ' + newVal.pupil_mata + ')'

          this.data.isian_dokter.objektif = pupilMatas
        }
      }
      // SUHU
      if (newVal.suhu) {
        var dataSuhu = this.data.isian_dokter.objektif
        //   // console.log(data + ' anak')
        if (typeof dataSuhu !== 'undefined') {
          var bracketsSuhu = dataSuhu.match(/\([^)]*\)/g)
          // console.log(bracketsSuhu + 'style')
          // console.log(brackets)
          var Suhu = '(P#Suhu: ' + newVal.suhu + ')'
          // console.log(tandavitalumum + ' asdasda')
          this.item_ke = null
          if (bracketsSuhu !== null) {
            bracketsSuhu.forEach((item, index) => {
              if (item.includes('(P#Suhu: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketsSuhu[this.item_ke],
                  Suhu
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                Suhu
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              Suhu
            )
          }
        } else {
          var Suhus = '(P#Suhu: ' + newVal.suhu + ')'

          this.data.isian_dokter.objektif = Suhus
        }
      }
      // SATURASI 02
      if (newVal.saturasi_O2) {
        var dataSaturasi02 = this.data.isian_dokter.objektif
        //   // console.log(data + ' anak')
        if (typeof dataSaturasi02 !== 'undefined') {
          var bracketsSaturasi02 = dataSaturasi02.match(/\([^)]*\)/g)

          var saturasiO2 = '(P#Saturasi 02: ' + newVal.saturasi_O2 + ')'
          this.item_ke = null
          if (bracketsSaturasi02 !== null) {
            bracketsSaturasi02.forEach((item, index) => {
              if (item.includes('(P#Saturasi 02: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  bracketsSaturasi02[this.item_ke],
                  saturasiO2
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                saturasiO2
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              saturasiO2
            )
          }
        } else {
          var Saturasi02s = '(P#Saturasi 02: ' + newVal.saturasi_O2 + ')'

          this.data.isian_dokter.objektif = Saturasi02s
        }
      }

      // BERAT BADAN DAN TINGGI BADAN
      if (newVal.berat_bedan || newVal.tinggi_badan) {
        var dataTinggiBadan = this.data.isian_dokter.objektif
        //   // console.log(data + ' anak')
        if (typeof dataTinggiBadan !== 'undefined') {
          var brecketTinggiBanda = dataTinggiBadan.match(/\([^)]*\)/g)

          var tinggiBadan =
            '(P#BB&TT: ' + newVal.berat_bedan + '&' + newVal.tinggi_badan + ')'
          this.item_ke = null
          if (brecketTinggiBanda !== null) {
            brecketTinggiBanda.forEach((item, index) => {
              if (item.includes('(P#BB&TT: ')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  brecketTinggiBanda[this.item_ke],
                  tinggiBadan
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                tinggiBadan
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              tinggiBadan
            )
          }
        } else {
          var tinggiBadans =
            '(P#BB&TT: ' + newVal.berat_bedan + '&' + newVal.tinggi_badan + ')'

          this.data.isian_dokter.objektif = tinggiBadans
        }
      }
    },
    'data.isian_dokter.kode_icd_diagnosa_utama' (a, b) {
      // console.log(a + '1111')
      // console.log(this.data.isian_dokter.diagnosa_utama)
      // eslint-disable-next-line no-unused-vars
      const icdygDipilih = a
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }
        // console.log(idcArray)
        // eslint-disable-next-line no-unused-vars
        var diagnosa = idcArray.join(',')
        // console.log(diagnosa)
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.isian_dokter.diagnosa_utama
        // console.log(data + ' anak')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          console.log(brackets + 'style')
          // console.log(brackets)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          // console.log(appendKeSubjektifDokter + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.diagnosa_utama =
                this.data.isian_dokter.diagnosa_utama.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.isian_dokter.diagnosa_utama = this.appendTechnique(
                this.data.isian_dokter.diagnosa_utama,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.isian_dokter.diagnosa_utama = this.appendTechnique(
              this.data.isian_dokter.diagnosa_utama,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.isian_dokter.diagnosa_utama = appendKeSubjektifDokters
        }
      }
    },
    'data.tanda_vital.anamnesis' (a, b) {
      var anamnesisPerawat = a.replace('[', '')

      var anamnesisPerawats = anamnesisPerawat.replace(']', '')
      // console.log(anamnesisPerawat)
      if (typeof a !== 'undefined') {
        var data = this.data.isian_dokter.subjektif
        //   // console.log(data + ' anak')
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          console.log(brackets + 'style')
          // console.log(brackets)
          var appendKeSubjektifDokter = '[' + anamnesisPerawats + ']'
          // console.log(appendKeSubjektifDokter + ' asdasda')
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.subjektif =
                this.data.isian_dokter.subjektif.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.isian_dokter.subjektif = this.appendTechnique(
                this.data.isian_dokter.subjektif,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.isian_dokter.subjektif = this.appendTechnique(
              this.data.isian_dokter.subjektif,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + anamnesisPerawats + ']'

          this.data.isian_dokter.subjektif = appendKeSubjektifDokters
        }
      }
    },
    'data.tanda_vital.jenis_pilihan' (a, b) {
      // console.log(a)
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.nama_keluhan
          // const icd = text[1]
          idcArray.push(text)
        }

        var diagnosa = idcArray.join(',')
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.tanda_vital.anamnesis
        // var dataSubjectif = this.data.isian_dokter.subjektif
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.tanda_vital.anamnesis =
                this.data.tanda_vital.anamnesis.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.tanda_vital.anamnesis = this.appendTechnique(
                this.data.tanda_vital.anamnesis,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.tanda_vital.anamnesis = this.appendTechnique(
              this.data.tanda_vital.anamnesis,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.tanda_vital.anamnesis = appendKeSubjektifDokters
        }

        // /// data aamnesis
        // if (typeof dataSubjectif !== 'undefined') {
        //   var brackets2 = dataSubjectif.match(/\[[^\]]*]/g)
        //   var appendKeSubjektifDokter2 = '[' + diagnosa + ']'
        //   this.item_ke = null
        //   if (brackets2 !== null) {
        //     brackets2.forEach((item, index) => {
        //       if (item.includes('[')) {
        //         this.item_ke = index
        //       }
        //     })
        //     if (this.item_ke != null) {
        //       this.data.isian_dokter.subjektif =
        //         this.data.isian_dokter.subjektif.replace(
        //           brackets2[this.item_ke],
        //           appendKeSubjektifDokter2
        //         )
        //     } else {
        //       this.data.isian_dokter.subjektif = this.appendTechnique(
        //         this.data.isian_dokter.subjektif,
        //         appendKeSubjektifDokter2
        //       )
        //     }
        //   } else {
        //     this.data.isian_dokter.subjektif = this.appendTechnique(
        //       this.data.isian_dokter.subjektif,
        //       appendKeSubjektifDokter2
        //     )
        //   }
        // } else {
        //   var appendKeSubjektifDokter2s = '[' + diagnosa + ']'

        //   this.data.isian_dokter.subjektif = appendKeSubjektifDokter2s
        // }
      }
    },
    'data.isian_dokter.kode_icd_diagnosa_sekunder' (a, b) {
      // console.log(a)
      // const icdygDipilih = a
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }

        var diagnosa = idcArray.join(',')
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.isian_dokter.diagnosa_sekunder
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.diagnosa_sekunder =
                this.data.isian_dokter.diagnosa_sekunder.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.isian_dokter.diagnosa_sekunder = this.appendTechnique(
                this.data.isian_dokter.diagnosa_sekunder,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.isian_dokter.diagnosa_sekunder = this.appendTechnique(
              this.data.isian_dokter.diagnosa_sekunder,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.isian_dokter.diagnosa_sekunder = appendKeSubjektifDokters
        }
      }
    },
    'data.isian_dokter.tindakan' (a, b) {
      if (typeof a !== 'undefined') {
        var tindakanArray = []
        for (const item of a) {
          const text = item.label
          // const icd = text[1]
          tindakanArray.push(text)
        }

        console.log(a)

        var tindakan = tindakanArray.join(',')
        // this.data.isian_dokter.tindakan_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.isian_dokter.tindakan_prosedur
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          var appendKeTindakanDokter = '[' + tindakan + ']'
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.tindakan_prosedur =
                this.data.isian_dokter.tindakan_prosedur.replace(
                  brackets[this.item_ke],
                  appendKeTindakanDokter
                )
            } else {
              this.data.isian_dokter.tindakan_prosedur = this.appendTechnique(
                this.data.isian_dokter.tindakan_prosedur,
                appendKeTindakanDokter
              )
            }
          } else {
            this.data.isian_dokter.tindakan_prosedur = this.appendTechnique(
              this.data.isian_dokter.tindakan_prosedur,
              appendKeTindakanDokter
            )
          }
        } else {
          var appendKeTindakanDokters = '[' + tindakan + ']'

          this.data.isian_dokter.tindakan_prosedur = appendKeTindakanDokters
        }
      }
    },
    'data.isian_dokter.diagnosa_utama' (a, b) {
      if (typeof a !== 'undefined') {
        var subjektif = a.replace('[', '')

        var subjektifA = subjektif.replace(']', '')
        var data = this.data.isian_dokter.asessment
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          var Subject = '[D:' + subjektifA + ']'
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[D:')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.asessment =
                this.data.isian_dokter.asessment.replace(
                  brackets[this.item_ke],
                  Subject
                )
            } else {
              this.data.isian_dokter.asessment = this.appendTechnique(
                this.data.isian_dokter.asessment,
                Subject
              )
            }
          } else {
            this.data.isian_dokter.asessment = this.appendTechnique(
              this.data.isian_dokter.asessment,
              Subject
            )
          }
        } else {
          var Subjects = '[D:' + subjektifA + ']'

          this.data.isian_dokter.asessment = Subjects
        }
      }
    }
  }
}
</script>
<style>
.float-group {
  position: fixed;
  bottom: 60px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 60px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
