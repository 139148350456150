<template>
  <v-dialog v-model="showDialog" :persistent="true" max-width="1280">
    <v-card>
      <v-card-title>Form Resep Non Racikan</v-card-title>
      <v-card-subtitle>
        <v-btn
          v-if="negativeButton"
          color="red darken-1"
          text
          :disabled="disabledNegativeBtn"
          @click="negativeButton"
        >
          Tutup
        </v-btn>
      </v-card-subtitle>
      <v-card-text>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-row>
            <v-col cols="12" lg="12" sm="12" md="12">
              <v-simple-table fixed-header height="900px">
                <thead>
                <tr>
                  <th width="2%">Aksi</th>
                  <th style="vertical-align: middle" width="33%" class="text-center">NAMA OBAT</th>
                  <th style="vertical-align: middle" width="25%" class="text-center">JUMLAH</th>
                  <th style="vertical-align: middle" width="40%" class="text-center">SIGNA/WAKTU PEMBERIAN</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(field, index) in fields" :key="index">
                  <td>
                    <v-btn color="red" @click="deleteitem(index)" fab
                           class="mt-1 ml-2 mr-2 btn-block" x-small dark>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                  <td style="vertical-align: middle" class="text-left">
                    <AutoComplateAjax
                      :sync-value="(e) => (field.value = e)"
                      class="mt-4 ml-2 mr-2"
                    ></AutoComplateAjax>
                    <v-textarea
                      solo
                      name="input-7-4"
                      class="mt-1 ml-2 mr-2"
                      label="Catatan"
                      filled
                      rows="1"
                      v-model="field.catatan"
                    ></v-textarea>
                  </td>
                  <td style="vertical-align: middle" class="text-left">
                    <v-text-field
                      label="Jumlah"
                      required
                      dense
                      type="number"
                      @keyup="checknumber(field.jumlah)"
                      v-model="field.jumlah"
                      class="mt-4 ml-2 mr-2"
                      outlined
                    ></v-text-field>
                  </td>
                  <td style="vertical-align: middle" class="text-left">
                    <v-text-field
                      label="Signa"
                      required
                      dense
                      v-model="field.signa"
                      class="mt-4 ml-2 mr-2"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      label="Waktu Pemberian"
                      required
                      v-model="field.waktu"
                      dense
                      class="mt-1 ml-2 mr-2"

                      outlined
                    ></v-text-field>
                  </td>

                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td>
                    <v-btn color="indigo darken-2" class="btn-block mt-2" dark @click="addField">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </td>
                  <td colspan="3">
                    <v-btn color="yellow darken-2" class="btn-block mt-2" dark @click="simpan">Simpan</v-btn>
                  </td>

                </tr>
                </tfoot>
              </v-simple-table>

            </v-col>
          </v-row>
        </v-col>

      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-if="negativeButton"
          color="green darken-1"
          text
          :disabled="disabledNegativeBtn"
          @click="negativeButton"
        >
          Tutup
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../../data/demografi-all'
import Dialog from '@/components/Dialog'
import AutoComplateAjax from '../AutoCompleteAjax.vue'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../../plugins/supports'

export default {
  name: 'Dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {
      },
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {
      },
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },

    itemUse: {
      require: false,
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget,
    AutoComplateAjax,
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog
  },
  data () {
    return {
      url_emr: 'http://emr.rs-syafira.com/',
      searchKonsultasi: '',
      test: '',
      permintaans_page: 1,
      regid: this.$route.query.registration_id,
      fields: [
        { value: '', catatan: '', jumlah: '', signa: '', waktu: '' }
      ],
      // autocompleteData: ['Option 1', 'Option 2', 'Option 3'],
      autocompleteData: [],
      permintaans_per_page: 9,
      permintaan_pagination_container: '',
      permintaans_total: '',
      isActive: false,
      items: ['Rawat Bersama', 'Ahli Rawat', 'Konsul'],
      master: Master,
      user: JSON.parse(localStorage.getItem('user')),
      namaDokter: '',
      listKonsultasi: [],
      pegawai: '',

      loading: false,
      sub: this.$route.query.sub_unit_id,
      formResep: {
        obat: '',
        signa: '',
        waktupemberian: '',
        keterangan: ''
      },
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      },
      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => this.postSave()
    }
  },
  mounted () {
    // this.getDataListKonsultasi()
  },
  created () {
    // this.set_contents(this.menukanan)
    if (this.$route.query.no_rm != null) {
      this.pegawai = JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    checknumber (e) {
      if (e === '0') {
        errorMsg('TIDAK BISA 0 JUMLAHNYA')
      }
    },
    deleteitem (index) {
      if (this.fields.length === 1) {
        return
      }
      this.fields.splice(index, 1)
    },
    simpan () {
      const strogeFormResep = 'form-resep' + this.$route.query.no_rm
      localStorage.setItem(strogeFormResep, JSON.stringify(this.fields))
      if (this.fields === 0) {
        errorMsg('DATA OBAT TIDAK BOLEH KOSONG')
        return true
      }
      // eslint-disable-next-line no-unused-vars
      // console.log(temparray)
      // const allSame = true
      const temparray = this.fields[0].value.id

      let checkIstrue = false
      let namaobat = ''
      this.fields.slice(1).forEach(function (obj, index) {
        console.log(obj.value.id)
        console.log(temparray)
        if (obj.value.id === temparray) {
          // return true
          checkIstrue = true
          namaobat = obj.value.text
        }
      })

      if (checkIstrue) {
        errorMsg(`NAMA OBAT ${namaobat} SUDAH ADA SILAHKAN DIPERIKSA KEMBALI RESEP ANDA TERIMA KASIH`)
        return true
      }

      var idcArray = []
      for (const item of this.fields) {
        // const text = item.text.split('/')
        // const icd = text[1]
        const icd =
          '<b>Nama Obat : </b><span class="text-danger">' +
          item.value.text +
          ' - </span><b>Signa : </b><span class="text-danger">' +
          item.signa +
          ' -</span><b>Waktu Pemberian : </b><span class="text-danger">' +
          item.waktu +
          '</span></br>'
        idcArray.push(icd)
      }

      // eslint-disable-next-line no-unused-vars
      const html = '<br> <b>Ingin Membuat Resep Dengan Item Di Atas?</b>'

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: `Perhatian! Apakah Anda Yakin Ingin Membuat Resep No. Racikan Dengan No.RM ${this.$route.query.no_rm} Dengan  Nama Pasien ${this.data.demografi.nama_pasien} ?`,
        text: 'Ingin Membuat Resep Non Racikan Ini Dengan No. Rekam Medis :',
        icon: 'warning',
        width: 980,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Buat Resep!',
        cancelButtonText: 'Tidak Jadi Batal',
        html: idcArray.join(' ') + html
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          const datas = LZString.compressToBase64(JSON.stringify(this.fields))

          const data = {
            no_rm: this.$route.query.no_rm,
            folio_id: this.$route.query.folio_id,
            registration_id: this.$route.query.registration_id,
            sub_unit_id: this.$route.query.sub_unit_id,
            data: datas
          }

          // eslint-disable-next-line no-undef
          $.post(baseUrl + 'igd/simpan-resep', data, (res) => {
            // eslint-disable-next-line no-undef,no-unused-vars
            const { con, msg, results } = res
            if (con) {
              successMsg(msg)
              this.fields = []
              const ini = this
              setTimeout(() => {
                ini.addField()
              }, 1000)
            } else {
              errorMsg(msg)
            }
          }, 'JSON')
        }
      })
      // eslint-disable-next-line no-undef

      // console.log(data)
    },
    addField () {
      this.fields.push({
        label: `Field ${this.fields.length + 1}`,
        value: '',
        catatan: '',
        jumlah: '',
        signa: '',
        waktu: ''
      })
    }

  },
  watch: {
    'fields.value' (a, b) {
      console.log('asdsad')
    }
  }
}
</script>
<style scoped>

</style>
