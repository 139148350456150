<template>
  <div>
    <div class="mt-1" v-if="data.demografi.notes">
      <v-alert dense shaped prominent type="error">
        Catatan Adm : {{ data.demografi.notes }}
      </v-alert>
    </div>
    <v-row justify="center">
      <v-col cols="12" lg="12" sm="12" md="12">
        <div class="mt-1">
          <v-card color="teal darken-1" elevation="5" dark>
            <v-card-text>
              <v-row class="m-3">
                <v-col
                  v-for="(v, i) in master.demografi"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-1 mb-0 pt-2 pb-1"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.demografi[i] = e)"
                    :value="data.demografi[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :disabled="v.disabled"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
      <!-- <v-col cols="12" lg="6" sm="6" md="6"> </v-col> -->
      <v-col cols="12" lg="12" sm="12" md="12">
        <div id="table-scroll" class="table-scroll">
          <div class="table-wrap mt-1">
            <table class="table table-bordered table-sm" width="100%">
              <thead>
                <tr class="table-primary">
                  <th
                    rowspan="2"
                    class="text-center"
                    style="vertical-align: middle; width: 10%"
                  >
                    TANGGAL/JAM
                  </th>
                  <th
                    rowspan="2"
                    class="text-center"
                    style="vertical-align: middle; width: 30%"
                  >
                    PPA
                  </th>
                  <th
                    colspan="2"
                    class="text-center"
                    style="vertical-align: middle; width: 50%"
                  >
                    <b>S-O-A-P</b>
                    <br />
                    <p>
                      (Dituliskan dengan format SOAP, <br />
                      diserati dengan target yang diukur, evaluasi hasil
                      <br />
                      tata laksana dituliskan dalam assessment).
                    </p>
                  </th>
                  <th
                    rowspan="2"
                    class="text-center"
                    style="vertical-align: middle; width: 20%"
                  >
                    VERIFIKASI DPJP
                  </th>
                </tr>
                <tr class="table-info">
                  <th class="text-center" style="vertical-align: middle">
                    Hasil Pemeriksaan
                  </th>
                  <th class="text-center" style="vertical-align: middle">
                    Instruksi Tenaga Kesehatan <br />
                    Termasuk Pasca Bedah/ Prosedur
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(itemList, index) of formcppt" :key="index">
                  <td style="text-align: center; vertical-align: middle">
                    {{ itemList.created_at }}
                  </td>
                  <td style="text-align: center; vertical-align: middle">
                    {{ itemList.profesi }}
                    <!-- {{ itemList.created_by }} -->
                    <div v-if="pemakai.employee_id == itemList.created_by">
                      <v-btn
                        class="m-1"
                        @click="deleteCppt(itemList)"
                        color="red"
                        dark
                        small
                        ><v-icon small>mdi-delete</v-icon> Hapus</v-btn
                      >
                      <v-btn
                        class="m-1"
                        @click="editCppt(itemList)"
                        color="orange darken-2"
                        dark
                        small
                        ><v-icon small>mdi-pencil</v-icon> Edit</v-btn
                      >
                    </div>
                  </td>
                  <td style="vertical-align: top">
                    <v-list three-line>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-alpha-s</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="itemList['subject']">
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-alpha-o</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="itemList['object']"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-alpha-a</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="itemList['asesmen']"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-icon>mdi-alpha-p</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="itemList['perencanaan']"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                  </td>
                  <td
                    style="
                      text-align: left;
                      vertical-align: top;
                      vertical-align: middle;
                    "
                  >
                    <b v-if="itemList.instruksi.length > 0">Verbal Order :</b>
                    <span v-html="itemList['instruksi']"></span>
                    <div

                    >
                      <hr />

                      <p v-if="itemList.petugas_1.nama_petugas.length > 0">
                        Nama Petugas 1 :
                        {{ itemList?.petugas_1.nama_petugas }}
                        <br />
                        Jam : {{ itemList?.petugas_1.jam }} <br />
                        Keterangan : {{ itemList?.petugas_1.lain_lainnya }}
                        <br />
                      </p>
                      <hr />
                      <p v-if="itemList.petugas_1.nama_petugas.length > 0">
                        Nama Petugas 2 :
                        {{ itemList?.petugas_2.nama_petugas }}
                        <br />
                        Jam : {{ itemList?.petugas_2.jam }} <br />
                        Keterangan : {{ itemList?.petugas_2.lain_lainnya }}
                        <br />
                      </p>
                    </div>
                  </td>
                  <td
                    style="
                      text-align: center;
                      vertical-align: top;
                      vertical-align: middle;
                    "
                  >
                    <span>{{ itemList.verifikasi_dpjp }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          style="z-index: 999999"
          class="float-group btn-group-vertical"
          role="group"
          aria-label="Vertical button group"
        >
          <v-btn
            class="mt-1 btn-block"
            color="red"
            dark
            v-if="showDialogCppt"
            @click="simpan"
            >Simpan CPPT</v-btn
          >
          <v-btn
            class="mt-1"
            v-if="hiddebutton"
            color="primary"
            @click="addcppt"
            >Tambah CPPT</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-dialog
      :persistent="persistent"
      v-model="showDialogCppt"
      :max-width="max"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="tutupDialogCppt()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Form CPPT</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <label class="mb-3 mt-2">🟦 <b>Subject</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.subject"
            :config="editorConfig"
          ></ckeditor>
          <label class="mb-3 mt-2">🟦 <b>Object</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.object"
            :config="editorConfig"
          ></ckeditor>
          <label class="mb-3 mt-2">🟦 <b>Asesmen</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.asesmen"
            :config="editorConfig"
          ></ckeditor>
          <label class="mb-3 mt-2">🟦 <b>Perencanaan</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.perencanaan"
            :config="editorConfig"
          ></ckeditor>
          <label class="mb-3 mt-2">🟦 <b>Verbal Order</b></label>
          <ckeditor
            :editor="editor"
            v-model="cpptLast.instruksi"
            :config="editorConfig"
          ></ckeditor>

          <p class="mb-1 mt-2"><b>Petugas 1 (Pemberi Instruksi)</b></p>
          <div class="form-group">
            <input
              type="text"
              v-model="cpptLast.verbal.petugas_1"
              class="form-control form-control-md"
              placeholder="Nama Petugas 1"
            />
          </div>
          <div class="form-group">
            <input
              type="time"
              v-model="cpptLast.verbal.jam_petugas_1"
              class="form-control form-control-md"
              placeholder="Jam"
            />
          </div>
          <div class="form-group">
            <textarea
              v-model="cpptLast.verbal.lain_nya_petugas_1"
              class="form-control form-control-md iinfo"
              placeholder="Lain-Lainnya"
            ></textarea>
          </div>

          <p class="mb-1 mt-0"><b>Petugas 2 (Penerima instruksi)</b></p>
          <div class="form-group">
            <input
              type="text"
              v-model="cpptLast.verbal.petugas_2"
              class="form-control form-control-md"
              placeholder="Nama Petugas 2"
            />
          </div>
          <div class="form-group">
            <input
              type="time"
              v-model="cpptLast.verbal.jam_petugas_2"
              class="form-control form-control-md"
              placeholder="Jam"
            />
          </div>
          <div class="form-group">
            <textarea
              v-model="cpptLast.verbal.lain_nya_petugas_2"
              class="form-control form-control-md iinfo"
              placeholder="Lain-Lainnya"
            ></textarea>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SmartWidget from '@/components/SmartWidget.vue'
// eslint-disable-next-line no-unused-vars
import Master from '../../data/cppt'
import Dialog from '@/components/Dialog'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

// import { apiKedua } from '../../plugins/supports'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog,

    // eslint-disable-next-line vue/no-unused-components
    SmartWidget
  },
  data () {
    return {
      master: Master,
      max: 980,
      showDialog: false,
      tab: '',
      jenis: '',
      persistent: true,
      hiddebutton: true,
      formcppt: [],
      createdBy: '',
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: '',
          notes: ''
        }
      },
      editor: ClassicEditor,
      editorConfig: {
        isReadOnly: false, // Atur ke false agar editor selalu dalam mode penyuntingan
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote'
        ]
      },
      pemakai: '',
      dataPatient: '',
      folioYangDipakai: '',
      showDialogCppt: false,
      fixed: true,
      search: '',
      no_rm: '',
      cpptLast: {
        id_cppt: '',
        subject: '',
        object: '',
        perencanaan: '',
        asesmen: '',
        instruksi: '',
        tanggal: '',
        verbal: {
          petugas_1: '',
          jam_petugas_1: '',
          lain_nya_petugas_1: '',
          petugas_2: '',
          jam_petugas_2: '',
          lain_nya_petugas_2: ''
        }
      },
      content: '',
      config: {
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
        ],
        height: 350
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  created () {
    if (!this.$route.query.no_rm) {
      errorMsg('PILIH DAHULU PASIENNYA')
      this.$router.push({
        name: 'DataPasienRawatInap'
      })
    }
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.pemakai = JSON.parse(localStorage.getItem('user'))
      this.getListSoap(this.$route.query.registration_id)
      this.cpptLast.verbal.petugas_2 = this.pemakai.employee_name
      this.drawer = localStorage.getItem('drawer') === false
      this.minivar = localStorage.getItem('minivar') === false

      // this.getAssemen(this.$route.query.folio_id)
    }
  },
  mounted () {
    // if (localStorage.getItem('drawer')) {
    this.drawer = localStorage.getItem('drawer') === false
    this.minivar = localStorage.getItem('minivar') === false
    // }
  },
  methods: {
    tutupDialogCppt () {
      this.persistent = false
      this.showDialogCppt = false
      this.hiddebutton = true
    },
    tutupDialog () {
      this.showDialog = false
      this.hiddebutton = true
    },
    konsultasi () {
      this.jenis = 'Konsultasi'
      this.showDialog = true
      this.hiddebutton = false
    },
    resumeMedis () {
      this.jenis = 'Resume'
      this.showDialog = true
      this.hiddebutton = false
    },
    lembarKontrol (item) {
      this.jenis = 'Kontrol'
      this.showDialog = true
      this.hiddebutton = false
      this.createdBy = item

      setTimeout(() => {
        this.$refs.kontrolpasien.getSuratKontrol()
        this.$refs.kontrolpasien.getListKontrol()
      }, 1000)
    },
    // checkButton (id) {
    //   if(this.pemakai.employee_id == id)
    //   return $thi
    // },
    editCppt (value) {
      this.showDialogCppt = true
      this.persistent = true
      // this.hiddebutton = false
      this.hiddebutton = false

      setTimeout(() => {
        this.max = this.randomRange(750, 800)
        console.log('sadas')
      }, 150)

      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'cppt/get-cppt-by-id',
        { id: value.aksi },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            // eslint-disable-next-line camelcase, no-unused-vars
            const petugas_1 = JSON.parse(results.petugas_1)
            // console.log(petugas_1)
            // eslint-disable-next-line camelcase, no-unused-vars
            const petugas_2 = JSON.parse(results.petugas_2)
            this.cpptLast.id_cppt = results.id_emr_cppt
            this.cpptLast.subject = results.subject
            this.cpptLast.object = results.object
            this.cpptLast.asesmen = results.asesmen
            this.cpptLast.perencanaan = results.perencanaan
            this.cpptLast.instruksi = results.instruksi
            this.cpptLast.verbal.petugas_1 = petugas_1.nama_petugas
            this.cpptLast.verbal.jam_petugas_1 = petugas_1.jam
            this.cpptLast.verbal.lain_nya_petugas_1 = petugas_1.lain_lainnya
            this.cpptLast.verbal.petugas_2 = petugas_2.nama_petugas
            this.cpptLast.verbal.jam_petugas_2 = petugas_2.jam
            this.cpptLast.verbal.lain_nya_petugas_1 = petugas_2.lain_lainnya
          } else {
          }
        },
        'json'
      )
    },
    deleteCppt (value) {
      var createdby = this.pemakai.employee_name

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Menghapus CPPT Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrl + 'cppt/hapus-by-id',
            { id: value.aksi, by: createdby },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                successMsg(msg)
                setTimeout(() => {
                  this.getListSoap()
                }, 1000)
              } else {
                errorMsg(msg + '-' + JSON.stringify(results))
              }
            },
            'JSON'
          )
        }
      })
      console.log(value)
    },

    addcppt () {
      this.showDialogCppt = true
      this.hiddebutton = false

      setTimeout(() => {
        this.max = this.randomRange(750, 800)
        this.cpptLast.id_cppt = ''
        this.cpptLast.subject = ''
        this.cpptLast.object = ''
        this.cpptLast.perencanaan = ''
        this.cpptLast.asesmen = ''
        this.cpptLast.instruksi = ''
        this.cpptLast.verbal.petugas_1 = ''
        this.cpptLast.verbal.jam_petugas_1 = ''
        this.cpptLast.verbal.lain_nya_petugas_1 = ''
        this.cpptLast.verbal.petugas_2 = this.pemakai.employee_name
        this.cpptLast.verbal.jam_petugas_2 = ''
        this.cpptLast.verbal.lain_nya_petugas_2 = ''
        console.log('sadas')
      }, 150)
    },
    randomRange (min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    simpan () {
      var createdby = this.pemakai.employee_id
      var namaPembuat = this.pemakai.employee_name
      var namaPasien = this.data.demografi.nama_pasien
      var norm = this.$route.query.no_rm
      var noreg = this.$route.query.registration_id
      var folioid = this.$route.query.folio_id
      // eslint-disable-next-line no-unused-vars
      var data = {
        cpptLast: this.cpptLast,
        namaPembuat: namaPembuat,
        createdby: createdby,
        namaPasien: namaPasien,
        norm: norm,
        noreg: noreg,
        folioid: folioid,
        verifikasi: this.pemakai.employee_name,
        type: 'igd',
        is_dokter: this.pemakai.is_dokter
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'cppt/simpan-cppt',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.getListSoap()
            if (!this.cpptLast.id_cppt) {
              this.cpptLast.id_cppt = ''
              this.cpptLast.subject = ''
              this.cpptLast.object = ''
              this.cpptLast.perencanaan = ''
              this.cpptLast.asesmen = ''
              this.cpptLast.instruksi = ''
              this.cpptLast.verbal.petugas_1 = ''
              this.cpptLast.verbal.jam_petugas_1 = ''
              this.cpptLast.verbal.lain_nya_petugas_1 = ''
              this.cpptLast.verbal.petugas_2 = ''
              this.cpptLast.verbal.jam_petugas_2 = ''
              this.cpptLast.verbal.lain_nya_petugas_2 = ''
            }
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
      console.log(data)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getListSoap () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl +
          'cppt/list-cppt-rawat-inap?reg_id=' +
          this.$route.query.registration_id +
          '&jenis=IGD',
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          console.log(results)
          // eslint-disable-next-line no-unused-vars
          const formcppt = []
          if (con) {
            // eslint-disable-next-line no-unused-vars
            for (const item of results) {
              formcppt.push({
                aksi: item.id_emr_cppt,
                verifikasi_dpjp: item.verifikasi_dpjp,
                created_at: item.created_at,
                no_reg: item.no_reg,
                subject: item.subject,
                profesi: item.profesi,
                object: item.object,
                created_by: item.created_by,
                asesmen: item.asesmen,
                perencanaan: item.perencanaan,
                instruksi: item.instruksi,
                petugas_1: JSON.parse(item.petugas_1),
                petugas_2: JSON.parse(item.petugas_2),
                is_double_check: item.is_double_check
              })
            }
          } else {
          }

          this.formcppt = formcppt
        },
        'JSON'
      )
    }
  }
}
</script>
<style>
.table-scroll {
  position: relative;
  max-width: 1800px;
  margin: auto;
  overflow: hidden;
  /* border: 1px solid #000; */
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  border: 0.5px solid #000;
  /* background: #fff; */
  white-space: nowrap;
  vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
  background: #f9f9f9;
}

.clone {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.clone th,
.clone td {
  visibility: hidden;
}

.clone td,
.clone th {
  border-color: transparent;
}

.clone tbody th {
  visibility: visible;
  color: red;
}

.clone .fixed-side {
  border: 1px solid #000;
  background: #eee;
  visibility: visible;
}

.clone thead,
.clone tfoot {
  background: transparent;
}
hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
