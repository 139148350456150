
<template>
  <v-dialog v-model="showDialog" :persistent="true" max-width="1380">
    <v-card>
      <v-card-title>Form Radiologi</v-card-title>
      <v-card-subtitle>
        <button
          v-if="negativeButton"
          :disabled="disabledNegativeBtn"
          @click="negativeButton"
          type="button"

          class="btn btn-danger btn-md text-white mt-3"
        >
          Tutup
        </button>
      </v-card-subtitle>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="12" lg="4" sm="4" md="4">
            <v-simple-table class="table table-bordered">
              <thead>
              <tr>
                <th style="vertical-align: middle" width="90%"><h6>Nama Tindakan</h6></th>
                <th style="vertical-align: middle;text-align: center"><h6>Aksi</h6></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(itemOrder, index) in listOrder"
                  :key="index">
                <td style="vertical-align: middle">
                  <b><u>{{ itemOrder.item_name }}</u></b>
                </td>
                <td style="vertical-align: middle" class="text-center">
                  <v-btn
                    fab
                    class="error"
                    @click="deleteTindakan(index)"
                    x-small
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn
                  >
                </td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td style="vertical-align: middle" colspan="2">
                  <v-textarea
                    class="mt-4 ml-2 mr-2"
                    auto-grow
                    outlined
                    label="Diagnosa/Keterangan Klinis Tambahan"
                    rows="4"
                    row-height="20"
                    v-model="keterangan"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <td style="vertical-align: middle" colspan="2">
                  <v-select
                    class="mt-4 ml-2 mr-2"
                    :items="itemsPilih"
                    label="Jenis Permintaan"
                    outlined
                    v-model="jenisPermintaan"
                  ></v-select>

                </td>

              </tr>
              <tr>
                <td style="vertical-align: middle" colspan="2">
                  <v-select
                    class="mt-4 ml-2 mr-2"
                    :items="PENGAMBILAN"
                    label="Radiologi"
                    outlined
                    v-model="diambil_id"
                  ></v-select>

                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <v-btn

                    class="btn-block ma-2"
                    color="primary darken-1"
                    @click="simpan"
                    :disabled="listOrder.length ===0"
                    dark
                    large
                  >
                    Order Radiologi
                  </v-btn>
                </td>
              </tr>

              </tfoot>
            </v-simple-table>
          </v-col>
          <v-col cols="12" lg="8" sm="8" md="8">

            <v-row class="ma-1">
              <v-col cols="12" lg="12" sm="12" md="12">
                <v-text-field
                  label="Cari Group Item"
                  v-model="searchQuery"
                  @keyup="cariitem"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                v-for="(itemlabor, indexlabor) of blanko"
                :key="indexlabor"
                cols="12"
                lg="4"
                sm="4"
                md="4"
              >
                <v-btn
                  color="teal darken-1"
                  class="btn-block"
                  large
                  style="font-size: 11px"
                  dark
                  @click="
                  setItem(
                    itemlabor.list,
                    itemlabor.item_group_id,
                    itemlabor.group_name
                  )
                "
                >
                  <b>{{ itemlabor.group_name }}</b>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    <v-dialog v-model="tol" max-width="500">
      <v-card>
        <v-card-title class="headline primary--text">
          {{ namaGroup }}
        </v-card-title
        >
        <v-card-text>
          <v-text-field
            label="Cari Nama Item Labor"
            v-model="searchQueryItem"
            outlined
            dense
          ></v-text-field>
          <ul class="list-group">
            <li
              v-for="(child, indexchild) of searchListItemDetail"
              :key="indexchild"
              class="list-group-item"
            >
              <v-btn
                style="font-size: 12px"
                class="btn-block"
                color="primary"
                medium
                dark
                @click="settindakan(child.item_id, child.item_name)"
              >
                <span v-if="child.item_group_id === group_id">{{ child.item_name }}
                </span>
              </v-btn>
            </li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '@/plugins/supports'
import Dialog from '@/components/Dialog'
import DialogAcc from '@/components/DialogAccRadiologi'
import AutoComplateAjax from '@/views/igd/AutoCompleteAjax.vue'

export default {
  name: 'Dialog',
  // eslint-disable-next-line vue/no-unused-components

  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {
      },
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {
      },
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },

    itemUse: {
      require: false,
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog,
    // eslint-disable-next-line vue/no-unused-components
    DialogAcc,
    // eslint-disable-next-line vue/no-unused-components
    AutoComplateAjax
  },
  data () {
    return {
      column: null,
      show: false,
      blanko: [],
      searchQuery: '',
      tol: false,
      listChild: [],
      listItem: [],
      searchQueryItem: '',
      namaGroup: '',
      snackbar: false,
      pesanError: 'Hello, Im a snackbar',
      waktuTimeOut: 200,
      listOrder: [],
      pesanOrder: 'List Order Radiologi',
      folioYangDipakai: '',
      items: '',
      keterangan: '',
      cekOrder: false,
      tab: '',
      diambil_id: '',
      itemsPilih: ['', 'CITO', 'PERMINTAAN'],
      PENGAMBILAN: ['', 'RADIOLOGI IGD', 'RADIOLOGI CENTRAL'],

      id_order: 0,
      reg: '',
      rm: '',
      jenisPermintaan: '',
      sub_unit_id: '',
      searchTindakan: '',

      folio: '',
      url_emr: 'http://emr.rs-syafira.com/',
      dialogShow: false,
      itemTindakan: [],
      tindakan: [],
      listHistoryOrder: [],
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => {
        this.dialogShow = false
      },
      // diaglogAcc
      dialogShowAcc: false,
      dialogTitleAcc: 'Konfirmasi Untuk Pemeriksaan Item Radiologi Ini',
      dialogMessageAcc: 'Apakah Item Ini Sudah Di Acc Untuk Di Periksa',
      dialogProgressAcc: false,
      idorderpenunjang: '',
      dialogDisableNBtnAcc: false,
      dialogDisablePBtnAcc: false,
      dialogActionNBtnAcc: () => {
        this.dialogShowAcc = false
      },
      dialogActionPBtnAcc: () => {
        // this.dialogShow = false
      },
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  created () {
    // this.detail_program = []
    if (this.$route.query.no_rm != null) {
      // console.log(this.master
      this.getData()
      this.getTypeItem()
      this.historyRadiologi()

      this.show = true
    }
  },
  computed: {
    getTindakan () {
      return this.itemTindakan.map((v) => ({
        text: v.item_name,
        value: v.item_id
      }))
    },
    searchListItemDetail () {
      // console.log('123213123')
      //   console.log(this.searchQuery);
      if (this.searchQueryItem) {
        // console.log(this.blanko)
        return this.listChild.filter((item) => {
          return item.item_name
            .toLowerCase()
            .includes(this.searchQueryItem.toLowerCase())
          // return item.item_name.startsWith(this.searchQueryItem.toLowerCase())
        })
      } else {
        return this.listChild
      }
    }
  },
  watch: {
    searchTindakan (val) {
      // console.log(val)
      if (val && (!this.tindakan || this.tindakan.text !== val)) {
        apiKedua
          .get('radiologi/get-tindakan?q=' + val, {})
          .then((response) => (this.itemTindakan = response.data.results))
          .catch(function (error) {
            // handle error
            console.log(error)
          })
          .then(function () {
            // always executed
          })
      }
    },

    loader () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => (this[l] = false), 3000)

      this.loader = null
    }
  },
  methods: {
    setItem (v, g, n) {
      this.tol = true
      this.listChild = v
      this.group_id = g
      this.namaGroup = n
    },
    getDataRadiologi () {

    },
    getData () {
      var blanko = []
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'radiologi/blanko-radiologi',
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            for (const item of results) {
              blanko.push({
                item_type_id: item.item_type_id,
                item_group_id: item.item_group_id,
                // list: this.getDataSingle(item.item_group_id),
                group_name: item.group_name,
                list: item.list
              })
            }
          } else {
          }
        },
        'JSON'
      )
      this.blanko = blanko
    },
    // eslint-disable-next-line camelcase
    historyRadiologi () {
      apiKedua
        .get('radiologi/history-order?rm=' + this.$route.query.no_rm)
        .then((res) => {
          if (res.data.con) {
            this.listHistoryOrder = res.data.results
          } else {
            this.listHistoryOrder = []
          }
          console.log(this.listHistoryOrder)
        })
    },
    getTypeItem () {
      apiKedua.get('radiologi/type-radiologi').then((res) => {
        if (res.data.con) {
          this.listItem = res.data.results
        }
      })
    },
    cariitem () {
      if (!this.searchQuery) {
        this.getData()
        return
      }
      // eslint-disable-next-line camelcase
      var array_item_group_id_hasil = []
      for (const item of this.blanko) {
        // console.log(item)
        var data = item.group_name
        var result = data
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase())
        if (result === true) {
          array_item_group_id_hasil.push(item.item_group_id)
        }

        if (result === false) {
          for (const force of item.list) {
            // eslint-disable-next-line camelcase
            var data_sub = force.item_name
            // eslint-disable-next-line camelcase
            var result_sub = data_sub
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase())
            // eslint-disable-next-line camelcase
            if (result_sub === true) {
              array_item_group_id_hasil.push(force.item_group_id)
            }
          }
          // $.each(field.list, function (x, force) {

          // })
        }
      }
      // eslint-disable-next-line camelcase, vue/no-side-effects-in-computed-properties
      // this.blanko = array_item_group_id_hasil
      console.log(array_item_group_id_hasil)
      // eslint-disable-next-line camelcase, no-unused-vars
      var first_generate = true
      var blanko = []
      var datas = this.blanko
      // eslint-disable-next-line no-unused-vars
      for (const itemhasil of datas) {
        // eslint-disable-next-line camelcase
        if (array_item_group_id_hasil.includes(itemhasil.item_group_id)) {
          blanko.push({
            item_type_id: itemhasil.item_type_id,
            item_group_id: itemhasil.item_group_id,
            // list: this.getDataSingle(item.item_group_id),
            group_name: itemhasil.group_name,
            list: itemhasil.list
          })
        }
      }

      this.blanko = blanko
    },
    changes (v) {
      // this.content = v
      apiKedua.get('radiologi/get-item-by-group?id_group=' + v).then((res) => {
        if (res.data.con) {
          this.items = res.data.results
        }
      })
    },
    settindakan (id, name) {
      this.id_order = this.id_order + 1

      var self = this
      this.listOrder.forEach((val, index) => {
        if (val.item_id === id) {
          self.listOrder.splice(index, 1)
          return false
        }
      })
      this.listOrder.push({
        no: this.id_order,
        item_id: id,
        item_name: name
      })

      this.tindakan = []

      // console.log(this.order)
    },
    deleteTindakan (v) {
      this.listOrder.splice(v, 1)
    },
    simpan () {
      // alert('asdsadas')
      if (this.listOrder.length === 0) {
        // console.log('Item Tidak Boleh Kosong')
        errorMsg('Item Tidak Boleh Kosong Dong')
      } else if (!this.keterangan) {
        errorMsg('Keterangan Klinis Tidak Boleh Kosong')
      } else if (!this.$route.query.no_rm) {
        errorMsg('Mohon Pilih Pasien Dahulu Yaaah')
      }

      // console.log(this.folioYangDipakai)
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        reg_id: this.$route.query.registration_id,
        keterangan: this.keterangan,
        item: this.listOrder,
        jenisPengambilan: this.diambil_id,
        jenisPermintaan: this.jenisPermintaan,
        sub_unit: this.$route.query.sub_unit_id
      }
      console.log(data)

      // eslint-disable-next-line no-undef
      $.post(baseUrl + 'igd/simpan-hasil-radiologi', data, (res) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = res
        if (con) {
          this.listOrder = []
          this.keterangan = ''
          this.jenisPermintaan = ''
          this.diambil_id = ''
          successMsg(msg)
        } else {
          errorMsg(msg + '')
        }
      })
    }
  }
}
</script>
<style>
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
tr {
  padding: 0 !important;
  margin: 0 !important;
}
</style>

<style scoped>

</style>
